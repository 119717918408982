import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';
import { GetOrganizationsAfterEdit } from './GetOrganizationsAfterEdit';
import { ValidatePassword } from '../../Validators/PasswordValidatorService';

export function EditOrganization(organization, instance) {
    let editOrg = {
        name: organization.name,
        orgAdminEmail: organization.orgAdminEmail,
        templates: organization.templates,
        videoMinutesLimit: organization.videoMinutesLimit,
        usersCountLimit: organization.usersCountLimit,
        templatesCountLimit: organization.templatesCountLimit
    };
    if (ValidatePassword(organization.password))
        editOrg["password"] = organization.password;
    $.ajax('/api/Organizations/' + organization.id, {
        type: 'PUT',
        data: JSON.stringify(editOrg),
        async: true,
        contentType: 'application/json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        success: function (output, status, xhr) {
            if (status == "success") {
                GetOrganizationsAfterEdit(instance);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus == "error")
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Process warning!</h1>
                                <p>Error while editing organization.</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
        }
    });
}