import jwt from 'jsonwebtoken';
import cookie from 'react-cookies';

export function ValidateToken() { 
    if (!sessionStorage.getItem("userData")) sessionStorage.setItem("userData", cookie.load('token'))
    let isInvalid = false;
    let token = null;
        if (token = sessionStorage.getItem("userData")) {
            const decoded = jwt.decode(token, { complete: true })
            if (decoded == null) {
                sessionStorage.clear();
                localStorage.clear();
                cookie.remove("token", { path: '/' });
                cookie.remove("isOneTimePassword", { path: '/' });
                isInvalid = true;
            }
            else {
                const payload = decoded.payload;
                if (payload.exp <= (Date.now() / 1000)) {
                    sessionStorage.clear();
                    localStorage.clear();
                    cookie.remove("token", { path: '/' });
                    cookie.remove("isOneTimePassword", { path: '/' });
                    isInvalid = true;
                }
            }
        }
    return isInvalid;
}