﻿
import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import '../../../Resources/Stylesheets/General/popup.css' 

export function RenderImageInput(inputInfo, instance) {
  return (
    <div className={instance.getInputClass(inputInfo.key)} key={inputInfo.key} item={inputInfo}>

      <input id={'file-' + inputInfo.key} accept=".png,.jpeg,.jpg" name={inputInfo.key} type="file"
             style={{ display: 'none' }} onChange={instance.handleInputChange} required={inputInfo.required}/>

      <div className="image-input-block">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <input id={inputInfo.key} className="image-input" type="text"
                      value={instance.state.inputs[inputInfo.key].name} disabled />

                  <div style={{ width: '175px', marginTop: '5px', paddingLeft: '10px' }}>
                      {instance.state.inputs[inputInfo.key].name === 'No picture' &&
                          <button type="button" className="add-btn add-btn-active" data-key={inputInfo.key} data-id={'file-' + inputInfo.key}
                          onClick={instance.openFileChooser} ><i className="fas fa-paperclip" />Select file</button>}

            {(instance.state.inputs[inputInfo.key].isUploading === true) &&
            <button type="button" className="add-btn add-btn-active" data-id={'file-' + inputInfo.key}><i
              style={{ fontSize: '20px', padding: '8px 0px' }} className="fas fa-circle-notch fa-spin"/></button>
            }

            {(instance.state.inputs[inputInfo.key].name !== 'No picture' && instance.state.inputs[inputInfo.key].href) &&
            <button type="button" className="add-btn add-btn-active" data-id={inputInfo.key}
                    onClick={instance.openImage}>
              <i className="fas fa-search"/>
              View file </button>}

                      {instance.state.inputs[inputInfo.key].href &&
                          <button type="button" className="remove-image" style={{ position: 'absolute', right: '125px', top: '15px' }} data-id={inputInfo.key}
                    onClick={instance.removeImage}><i
              className="far fa-times-circle"/></button>}
          </div>
        </div>
      </div>

      <label htmlFor={'file-' + inputInfo.key}>{inputInfo.label}
        <i style={{bottom: "15px"}} className="far fa-question-circle">
          <div>Upload PNG or JPG/JPEG images</div>
        </i>
      </label>

    </div>
  )
}