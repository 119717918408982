import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';

export function ShareByEmail(video, email, recaptcha) { 
    $.ajax('/api/Share/Email', {
        type: 'POST',
        async: true,
        contentType: 'application/json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        data: JSON.stringify({ videoId: video.id, email: email, reCaptchaToken: recaptcha }),
        success: function (output, status, xhr) {
            if (status == "success") {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-check-double" />Done!</h1>
                                <p>E-mail sent.</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus == "error")
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Process warning!</h1>
                                <p>Error while sharing video by email.</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
        }
    });
} 