import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';
import { GenerateInputObject } from './GenerateInputObject';

export function GetTemplateDetail(key, instance) {
    $.ajax('/api/Templates/detail?key=' + key, {
        type: 'GET',
        async: true,
        contentType: 'application/json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        success: function (output, status, xhr) {
            if (status == "success") {
                const objects = GenerateInputObject(output); 
                instance.setState({ template: output, templateLoaded: true, inputs: objects[0], validators: objects[1] });
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
        }
    });
}