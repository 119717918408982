import $ from 'jquery';
import React, { Component } from 'react';
import cookie from 'react-cookies';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../Resources/Stylesheets/General/popup.css';

export function LoginAuth(userData, instance) {
    $.ajax('/api/Auth/Login', {
        data: JSON.stringify(userData), 
        type: 'POST',
        async: true,
        contentType: 'application/json', 
        success: function (output, status, xhr) {
            if (status == "success") {
                sessionStorage.setItem("userData", output.token);
                cookie.save("token", output.token, { path: '/' });
                if (output.isOneTimePassword) {
                    cookie.save("isOneTimePassword", output.isOneTimePassword, { path: '/' });
                    instance.setState({ redirectOnetime: true });
                }
                else instance.setState({ redirect: true });
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus == "error") {
                instance.setState({ logging: false });
                instance.checkInputLabels("email");
                instance.checkInputLabels("password");
                sessionStorage.clear();
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Authentication warning!</h1>
                                <p>User not found.</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
            }
        },
        cache: false
    });
}