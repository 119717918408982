import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'
import { ValidateToken } from '../Services/TokenValidityService'
import '../Resources/Stylesheets/Organizations/organizations.css'
import { AddNewOrganization } from '../Services/OrganizationServices/AddOrganizationService'
import { DeleteOrganizationById } from '../Services/OrganizationServices/DeleteOrganizationByIdService'
import { GetOrganizations } from '../Services/OrganizationServices/GetOrganizationsService'
import { EditOrganization } from '../Services/OrganizationServices/EditOrganizationService'
import { GetOrganizationLogo } from '../Services/OrganizationServices/GetOrganizationLogoService';
import { Identity } from '../Services/IdentityService'
import moment from 'moment'
import { UploadLogo } from './../Services/OrganizationServices/UploadLogoService';
import { ChangeOrganizationLogo } from './../Services/OrganizationServices/ChangeOrganizationLogoService';
import { confirmAlert } from 'react-confirm-alert'
import { ValidateEmail } from '../Validators/EmailValidatorService'
import { ValidatePassword } from '../Validators/PasswordValidatorService'
import { ValidateName } from '../Validators/NameValidatorService'
import { ValidateTemplate } from '../Validators/TemplateValidatorService'
import { GetTemplateOverview } from '../Services/TemplatesServices/GetTemplateOverviewService'
import { RenderLoader } from '../Components/General/Loader'
import { ValidateNumber } from '../Validators/NumberValidatorService'
import { ValidateUrl } from '../Validators/UrlValidatorService'
import { GetRole, Roles } from '../Services/RoleService'
import NoAuthenticated from './NoAuthenticated'
import Switch from "./../Components/FormComponents/Switch"
import { RenderLogoInput } from './Settings/Components/LogoInput'

export class Organizations extends Component {
  static displayName = Organizations.name

  constructor(props) {
    super(props)
    this.state = {
      success: 2,
      redirect: false,
      newOrganization: {
        name: '',
        orgAdminEmail: '',
        password: '',
        key: '',
          webUrl: '',
          ctaVisible: false,
          ctaUrl: '',
        ctaAvailable: false,
        templateName: '',
        videoMinutesLimit: 0,
        usersCountLimit: 0,
        templatesCountLimit: 0,
        templates: [],
      },
      organizations: [],
      addOrgValidators: {
        name: null,
        orgAdminEmail: null,
        password: null,
        videoMinutesLimit: true,
        templatesCountLimit: true,
        usersCountLimit: true,
        key: null,
          webUrl: null,
        ctaUrl: null
      },
      loading: true,
      isAddValid: false,
      nameError: false,
      editOrganization: null,
      isEditValid: false,
      editOrgValidators: {
        name: null,
        orgAdminEmail: null,
        password: null,
        videoMinutesLimit: null,
        templatesCountLimit: null,
        usersCountLimit: null,
        key: null,
        },
        isUploading: false,
        orgLoading: true,
        orgLogo: null
    }
    this.handleAddOrgInputChange = this.handleAddOrgInputChange.bind(this)
    this.handleEditOrgInputChange = this.handleEditOrgInputChange.bind(this)
    this.addOrganization = this.addOrganization.bind(this)
    this.toggleAddOrganization = this.toggleAddOrganization.bind(this)
    this.toggleEditOrg = this.toggleEditOrg.bind(this)
    this.deleteOrganization = this.deleteOrganization.bind(this)
    this.getAddInputClass = this.getAddInputClass.bind(this)
    this.getEditInputClass = this.getEditInputClass.bind(this)
    this.checkAddValidators = this.checkAddValidators.bind(this)
    this.checkEditValidators = this.checkEditValidators.bind(this)
    this.getAddBtnClass = this.getAddBtnClass.bind(this)
    this.getEditBtnClass = this.getEditBtnClass.bind(this)
    this.orgAddNewKey = this.orgAddNewKey.bind(this)
    this.orgEditNewKey = this.orgEditNewKey.bind(this)
    this.closeAllEdits = this.closeAllEdits.bind(this)
    this.editOrg = this.editOrg.bind(this)
    this.orgAddDeleteKey = this.orgAddDeleteKey.bind(this)
      this.orgEditDeleteKey = this.orgEditDeleteKey.bind(this)
      this.handleCtaValueChanged = this.handleCtaValueChanged.bind(this)
    }

    openFileChooser(event) {
        $("#" + event.currentTarget.dataset.id).click();
        event.preventDefault();
    }

    handleLogoInputChange(instance, e) {
        if (e.target.files.length > 0) {
            var oldOrgLogo = this.state.orgLogo;
            this.setState({ isUploading: true, orgLogo: e.target.files[0].name });
            UploadLogo(this, e.target.files[0], this.state.editOrganization.id, oldOrgLogo);
        }
    }
    removeImage(instance, e) {
        ChangeOrganizationLogo("", this.state.editOrganization.id, this);
    } 

  componentWillMount() {
    ValidateToken()
    document.title = 'Organizations | Personalizard'
    const _identity = Identity()
    if (sessionStorage.getItem('userData')) {
      if (GetRole(_identity.role !== Roles.User && GetRole(_identity.role !== Roles.OrgAdmin))) {
        GetOrganizations(this)
        this.setState({
          identity: _identity, addOrgValidators: {
            name: null,
            orgAdminEmail: null,
            password: null,
            videoMinutesLimit: true,
            key: null,
          }, AddNewOrganization: {
            name: '',
            orgAdminEmail: '',
            password: '',
            videoMinutesLimit: 0,
            usersCountLimit: 0,
            templatesCountLimit: 0,
            templates: [],
          },
          isAuthenticated: true,
        })
      } else {
        this.setState({ isAuthenticated: false, loading: false })
      }
    } else {
      this.setState({ redirect: true })
    }
  }

  validateNameByDuplicated(name) {
    let orgsArray = this.state.organizations
    for (let i = 0; i < orgsArray.length; i++) {
      if (orgsArray[i].name === name) {
        this.setState({
          nameError: true,
        })
        return false
      } else {
        this.setState({
          nameError: false,
        })
        return true
      }
    }
    return true
  }

  handleAddOrgInputChange(event) {
    let orgVals = this.state.newOrganization
    let validatorsVals = this.state.addOrgValidators
    switch (event.target.name) {
      case 'name':
        validatorsVals.name = ValidateName(event.target.value)
        validatorsVals.name = this.validateNameByDuplicated(event.target.value)
        this.setState({ addOrgValidators: validatorsVals })
        break
      case 'orgAdminEmail':
        validatorsVals.orgAdminEmail = ValidateEmail(event.target.value)
        this.setState({ addOrgValidators: validatorsVals })
        break
      case 'password':
        validatorsVals.password = ValidatePassword(event.target.value)
        this.setState({ addOrgValidators: validatorsVals })
        break
      case 'videoMinutesLimit':
        validatorsVals.videoMinutesLimit = ValidateNumber(event.target.value)
        this.setState({ addOrgValidators: validatorsVals })
        break
      case 'templatesCountLimit':
        validatorsVals.templatesCountLimit = ValidateNumber(event.target.value)
        this.setState({ addOrgValidators: validatorsVals })
        break
      case 'usersCountLimit':
        validatorsVals.usersCountLimit = ValidateNumber(event.target.value)
        this.setState({ addOrgValidators: validatorsVals })
        break
      case 'key':
        ValidateTemplate(event.target.value, this.state.newOrganization.templates, this, 'addOrgValidators')
        this.setState({ addOrgValidators: validatorsVals })
            break
        case 'webUrl':
            validatorsVals.webUrl = ValidateUrl(event.target.value, true)
            this.setState({ addOrgValidators: validatorsVals })
            break
        case 'ctaUrl':
            validatorsVals.ctaUrl = ValidateUrl(event.target.value, true)
            this.setState({ addOrgValidators: validatorsVals })
            break

    }
    orgVals[event.target.name] = event.target.value
    this.setState({ newOrganization: orgVals })
    this.checkAddValidators()
  }

  handleEditOrgInputChange(event) {
    let orgVals = this.state.editOrganization
    let validatorsVals = this.state.editOrgValidators
    switch (event.target.name) {
      case 'name':
        validatorsVals.name = ValidateName(event.target.value)
        this.setState({ editOrgValidators: validatorsVals })
        break
      case 'orgAdminEmail':
        validatorsVals.orgAdminEmail = ValidateEmail(event.target.value)
        this.setState({ editOrgValidators: validatorsVals })
        break
      case 'password':
        validatorsVals.password = ValidatePassword(event.target.value)
        this.setState({ editOrgValidators: validatorsVals })
        break
      case 'videoMinutesLimit':
        validatorsVals.videoMinutesLimit = ValidateNumber(event.target.value)
        this.setState({ editOrgValidators: validatorsVals })
        break
      case 'templatesCountLimit':
        validatorsVals.templatesCountLimit = ValidateNumber(event.target.value)
        this.setState({ editOrgValidators: validatorsVals })
        break
      case 'usersCountLimit':
        validatorsVals.usersCountLimit = ValidateNumber(event.target.value)
        this.setState({ editOrgValidators: validatorsVals })
        break
      case 'key':
        ValidateTemplate(event.target.value, this.state.editOrganization.templates, this, 'editOrgValidators')
        this.setState({ editOrgValidators: validatorsVals })
        break
    }
    orgVals[event.target.name] = event.target.value
    this.setState({ editOrganization: orgVals })
    this.checkEditValidators()
  }

  orgAddNewKey(event) {
    if (this.state.addOrgValidators.key === true) {
      let newOrgVals = this.state.newOrganization
      let orgValidators = this.state.addOrgValidators
      let template = GetTemplateOverview(newOrgVals.key)
      template.webUrl = this.state.newOrganization.webUrl
        template.name = this.state.newOrganization.templateName
        template.ctaUrl = this.state.newOrganization.ctaUrl
        template.ctaVisible = this.state.newOrganization.ctaVisible
      newOrgVals.templates.push(template)
      newOrgVals.key = ''
      newOrgVals.webUrl = ''
        newOrgVals.templateName = ''
        newOrgVals.ctaVisible = false
        newOrgVals.ctaUrl = ''
      orgValidators.key = null
      this.setState({ newOrganization: newOrgVals, addOrgValidators: orgValidators })
    }
    event.preventDefault()
  }

  orgAddDeleteKey(event) {
    const keyId = event.currentTarget.dataset.id
    let newOrg = this.state.newOrganization
    newOrg.templates = newOrg.templates.filter((value, index, array) => {
      return value.id != keyId
    })
    this.setState({ newOrganization: newOrg })
  }

  orgEditDeleteKey(event) {
    const keyId = event.currentTarget.dataset.id
    let editOrg = this.state.editOrganization
    editOrg.templates = editOrg.templates.filter((value, index, array) => {
      return value.id != keyId
    })
    this.checkEditValidators()
    this.setState({ editOrganization: editOrg })
  }

  orgEditNewKey(event) {
    if (this.state.editOrgValidators.key == true) {
      let editOrgVals = this.state.editOrganization
      let orgValidators = this.state.editOrgValidators
      let template = GetTemplateOverview(editOrgVals.key)
      editOrgVals.templates.push(template)
      editOrgVals.key = ''
      orgValidators.key = null
      this.setState({ editOrganization: editOrgVals, editOrgValidators: orgValidators })
    }
    event.preventDefault()
  }

  savedSuccess() {
    setTimeout(() => {
      this.setState({
        success: 2,
      })
    }, 2000)
    return (
      <div>Saved</div>
    )
  }

  resolveButtonLabel() {

    switch (this.state.success) {
      case 0:
        return 'Saving...'
      case 1:
        return this.savedSuccess()
      case 2:
        return 'Add organization'
      default:
        break

    }
  }


  addOrganization(event) {
    if (this.state.isAddValid) {
      let orgVals = this.state.newOrganization
      delete orgVals.key
      delete orgVals.templateName
      this.setState({
        success: 0,
      })
      AddNewOrganization(orgVals, this)
      this.setState({
        newOrganization: {
          name: '',
          orgAdminEmail: '',
          password: '',
          key: '',
              webUrl: '',
              ctaUrl: '',
              ctaVisible: false,
          ctaAvailable: false,
          videoMinutesLimit: 0,
          templatesCountLimit: 0,
          usersCountLimit: 0,
          templates: [],
        }, isAddValid: false, addOrgValidators: {
          name: null,
          orgAdminEmail: null,
          password: null,
          videoMinutesLimit: true,
          templatesCountLimit: true,
          usersCountLimit: true,
          key: null,
        },
      })
    }
    event.preventDefault()
  }

  editOrg(event) {
    if (this.state.isEditValid) {
      let orgVals = this.state.editOrganization
      delete orgVals.key
      EditOrganization(orgVals, this)
      this.closeAllEdits()
      this.setState({
        editOrganization: null, isEditValid: false, editOrgValidators: {
          name: null,
          orgAdminEmail: null,
          password: null,
          videoMinutesLimit: null,
          usersCountLimit: null,
          templatesCountLimit: null,
          key: null,
        },
      })
    }
    event.preventDefault()
  }

  deleteOrganization(event) {
    let params = event.currentTarget.dataset
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1><i className="fas fa-exclamation-triangle"></i>Delete warning!</h1>
            <p>This action is permanent. Would you like to proceed?</p>
            <div className="line"></div>
            <button className="yes"
                    onClick={() => {
                      DeleteOrganizationById(params.id, this)
                      onClose()
                    }}
            >
              <i className="far fa-trash-alt"></i>Yes
            </button>
            <button className="no" onClick={onClose}>No</button>
          </div>
        )
      },
    })
    event.preventDefault()
  }

    handleCtaValueChanged(id, val) {
        let newOrg = this.state.newOrganization;
        newOrg[id] = val;
        this.setState({ newOrganization: newOrg });
    }

  toggleEditOrg(event) {
    const active = $(event.currentTarget).hasClass('active-item')
    this.closeAllEdits()
    if (active) $(event.currentTarget).removeClass('active-item')
    else $(event.currentTarget).addClass('active-item')
      if (!active) {
          $(event.currentTarget).parent().parent().parent().children('.org-edit').toggle()
          let object = JSON.parse(event.currentTarget.dataset.item)
          object.password = ''
          object.key = ''
          GetOrganizationLogo(this, object.id);
          this.setState({
              editOrganization: object,
              isEditValid: false,
              editOrgValidators: {
                  name: ValidateName(object.name),
                  orgAdminEmail: ValidateEmail(object.orgAdminEmail),
                  password: ValidatePassword(object.password),
                  videoMinutesLimit: ValidateNumber(object.videoMinutesLimit),
                  usersCountLimit: ValidateNumber(object.usersCountLimit),
                  templatesCountLimit: ValidateNumber(object.templatesCountLimit),

                  key: ValidateTemplate(object.key),
              },
          })
      }
      else {
          this.setState({ orgLoading: true, orgLogo: null });
      }
    event.preventDefault()
  }

  closeAllEdits() {
    $('.org-edit').each(function() {
      $(this).hide()
    })
    $('.active-item').each(function() {
      $(this).removeClass('active-item')
    })
  }

  toggleAddOrganization() {
    if ($('.page-top-panel-body').is(':hidden')) $('.page-top-panel-header i').css({
      transform: 'rotateZ(180deg)',
      bottom: '2.5px',
    })
    else $('.page-top-panel-header i').css({ transform: 'rotateZ(0deg)', bottom: '0' })
    $('.page-top-panel-body').toggle()
    this.setState({
      newOrganization: {
        name: '',
        orgAdminEmail: '',
        password: '',
        key: '',
            webUrl: '',
            ctaUrl: '',
            ctaVisible: false,
        videoMinutesLimit: 0,
        usersCountLimit: 0,
        templatesCountLimit: 0,
        templates: [],
      }, isAddValid: false, addOrgValidators: {
        name: null,
        orgAdminEmail: null,
        password: null,
        videoMinutesLimit: true,
        key: null,
      },
    })
  }

  // One organization card
  static renderSingleOrganization(organization, instance) {
    return (
      <div className="items-block" key={organization.orgAdminEmail} item={organization}>
        <div className="orgs-item">
          <h1 className="org-name">
            <i className="fas fa-globe"></i>
            {organization.name}
          </h1>
          <div className="org-email-controls">
            <div className="org-email">
              <i className="far fa-envelope"></i>
              {organization.orgAdminEmail}
            </div>
            <div className="org-controls">
              <button className="org-controls-item delete-item" data-item={JSON.stringify(organization)}
                      onClick={instance.toggleEditOrg}>
                <i className="fas fa-pen"></i>
                Edit
              </button>
              <button className="org-controls-item delete-item" data-id={organization.id}
                      onClick={instance.deleteOrganization}>
                <i className="far fa-trash-alt"></i>
                Delete
              </button>
            </div>
          </div>
          <div className="org-stats">
            <span>Temps: {organization.templates.length}</span>
            <span>Videos: {organization.videosCount}</span>
            <span>Users: {organization.usersCount}</span>
            <span>Created: {moment(organization.createdDate).format('YYYY/M/D')}</span>
          </div>
                <div className="org-edit">
                    {!instance.state.orgLoading && instance.state.editOrganization != null && RenderLogoInput(instance)}
            {instance.state.editOrganization != null &&
            <form onSubmit={instance.editOrg}>
              <div className={instance.getEditInputClass('name')}>
                <input id="org-edit-name" type="text" name="name" value={instance.state.editOrganization.name}
                       onChange={instance.handleEditOrgInputChange} required/>
                <label htmlFor="org-edit-name">Name</label>
              </div>
              <div className={instance.getEditInputClass('orgAdminEmail')}>
                <input id="org-edit-email" type="email" name="orgAdminEmail"
                       value={instance.state.editOrganization.orgAdminEmail}
                       onChange={instance.handleEditOrgInputChange} required/>
                <label htmlFor="org-edit-email">Email of orgadmin</label>
              </div>
              <div className={instance.getEditInputClass('password')}>
                <input id="org-edit-password" type="password" name="password"
                       value={instance.state.editOrganization.password} onChange={instance.handleEditOrgInputChange}/>
                <label htmlFor="org-edit-password">One-use password</label>
                <i className="far fa-question-circle">
                  <div className="quesPopup">At least 6 chars</div>
                </i>
              </div>
              <div className={instance.getEditInputClass('videoMinutesLimit')}>
                <input id="org-edit-videoMinutesLimit" type="number" min="0" name="videoMinutesLimit"
                       value={instance.state.editOrganization.videoMinutesLimit}
                       onChange={instance.handleEditOrgInputChange}/>
                            <label htmlFor="org-edit-videoMinutesLimit">Max rendered videos limit</label>
                <i className="far fa-question-circle">
                  <div className="quesPopup">Value 0 means unlimited.</div>
                </i>
              </div>
              <div className={instance.getEditInputClass('videoMinutesLimit')}>
                <input id="org-userLimit" type="number" min="0" name="usersCountLimit"
                       value={instance.state.editOrganization.usersCountLimit}
                       onChange={instance.handleEditOrgInputChange}/>
                <label htmlFor="org-userLimit">User limit</label>
                <i className="far fa-question-circle">
                  <div className="quesPopup">Value 0 means unlimited.</div>
                </i>
              </div>
              <div className={instance.getEditInputClass('videoMinutesLimit')}>
                <input id="org-templateLimit" type="number" min="0" name="templatesCountLimit"
                       value={instance.state.editOrganization.templatesCountLimit}
                       onChange={instance.handleEditOrgInputChange}/>
                <label htmlFor="org-templateLimit">Template limit</label>
                <i className="far fa-question-circle">
                  <div className="quesPopup">Value 0 means unlimited.</div>
                </i>
              </div>
              <div className={instance.getEditInputClass('key')}>
                <button className={instance.getEditKeyBtnClass()} onClick={instance.orgEditNewKey}><i
                  className="fas fa-plus"></i>Add
                </button>
                <input id="org-edit-key" className="key-input" type="text" name="key"
                       value={instance.state.editOrganization.key} onChange={instance.handleEditOrgInputChange}/>
                <label htmlFor="org-edit-key">Template key</label>
              </div>
              <div className="form-label-input-block">
                <div>
                  {instance.state.editOrganization.templates.map(template =>
                    <div key={template.id}>
                      <button type="button" className="remove-template" data-id={template.id}
                              onClick={instance.orgEditDeleteKey}><i className="far fa-times-circle"></i></button>
                      {template.name}</div>,
                  )}
                </div>
                <label htmlFor="org-key">Added templates</label>
              </div>
              <button disabled={!instance.state.isEditValid} className={instance.getEditBtnClass()}>Edit organization
              </button>
            </form>
            }
          </div>
        </div>
      </div>
    )
  }

  static renderOrganizations(organizations = [], instance) {
    if (organizations.length <= 0)
      return (
        <div className="items-block">
          <div className="items-message">No organizations found</div>
        </div>
      )
    else
      return (
        <div className="items-block">
          {organizations.map(organization => Organizations.renderSingleOrganization(organization, instance))}
        </div>
      )
  }

  getAddInputClass(name) {
    if (this.state.addOrgValidators[name] == null) return ('form-label-input-block')
    else if (this.state.addOrgValidators[name] == false) return ('form-label-input-block nonvalid-field')
    else return ('form-label-input-block valid-field')
  }

  getEditInputClass(name) {
    if (this.state.editOrgValidators[name] == null) return ('form-label-input-block')
    else if (this.state.editOrgValidators[name] == false) return ('form-label-input-block nonvalid-field')
    else return ('form-label-input-block valid-field')
  }

  checkAddValidators() {
    const validators = this.state.addOrgValidators
    if (validators.name == true && validators.orgAdminEmail == true && validators.password == true && validators.videoMinutesLimit == true) this.setState({ isAddValid: true })
    else this.setState({ isAddValid: false })
  }

  checkEditValidators() {
    const validators = this.state.editOrgValidators
    if (validators.name == true && validators.orgAdminEmail == true && validators.videoMinutesLimit == true && (validators.password == true || validators.password == null)) this.setState({ isEditValid: true })
    else this.setState({ isEditValid: false })
  }

  getAddBtnClass() {
    if (this.state.isAddValid) return ('form-submit-button form-submit-button-active')
    if (this.state.success === 1) return ('form-submit-button-success-fetch')
    else return ('form-submit-button')
  }

  getAddKeyBtnClass() {
    if (this.state.addOrgValidators.key == true) return ('add-btn add-btn-active')
    else return ('add-btn')
  }

  getEditKeyBtnClass() {
    if (this.state.editOrgValidators.key == true) return ('add-btn add-btn-active')
    else return ('add-btn')
  }

  getEditBtnClass() {
    if (this.state.isEditValid) return ('form-submit-button form-submit-button-active')
    else return ('form-submit-button')
  }

  render() {
    if (this.state.redirect) return (<Redirect to={'/login'}/>)
    let loader = this.state.loading ? RenderLoader() : ''
    let organizationsContent = this.state.loading
      ? <div className="items-block">
        <div className="items-message">Loading...</div>
      </div>
      : Organizations.renderOrganizations(this.state.organizations, this)

    return (
      <div className="main-content block-layout">
        {loader}
        {!this.state.isAuthenticated && <NoAuthenticated/>}
        {this.state.isAuthenticated &&

        <section className="section-layout">
          <div className="page-top-panel">
            <div className="page-top-panel-header" onClick={this.toggleAddOrganization}>
              <h1>Add organization</h1>
              <i className="fas fa-chevron-down"></i>
            </div>
            <div className="page-top-panel-body">
              <form onSubmit={this.addOrganization}>
                <div className={this.getAddInputClass('name')}>
                  <input id="org-name" type="text" name="name" value={this.state.newOrganization.name}
                         onChange={this.handleAddOrgInputChange} required/>
                  <label htmlFor="org-name">Name</label>
                </div>
                {this.state.nameError &&
                <div style={{ color: 'red', fontSize: '12px', marginBottom: '20px', marginTop: '-12px' }}>
                  Name already exists
                </div>}
                <div className={this.getAddInputClass('orgAdminEmail')}>
                  <input id="org-email" type="email" name="orgAdminEmail"
                         value={this.state.newOrganization.orgAdminEmail}
                         onChange={this.handleAddOrgInputChange}
                         required/>
                  <label htmlFor="org-email">Email of orgadmin</label>
                </div>
                <div className={this.getAddInputClass('password')}>
                  <input id="org-password" type="password" name="password"
                         value={this.state.newOrganization.password}
                         onChange={this.handleAddOrgInputChange} required/>
                  <label htmlFor="org-password">One-use password</label>
                  <i className="far fa-question-circle">
                    <div className="quesPopup">At least 6 chars</div>
                  </i>
                </div>
                <div className={this.getAddInputClass('videoMinutesLimit')}>
                  <input id="org-videoMinutesLimit" type="number" min="0" name="videoMinutesLimit"
                         value={this.state.newOrganization.videoMinutesLimit}
                         onChange={this.handleAddOrgInputChange}/>
                  <label htmlFor="org-videoMinutesLimit">Max rendered videos limit</label>
                  <i className="far fa-question-circle">
                    <div className="quesPopup">Value 0 means unlimited.</div>
                  </i>
                </div>
                <div className={this.getAddInputClass('videoMinutesLimit')}>
                  <input id="org-userLimit" type="number" min="0" name="usersCountLimit"
                         value={this.state.newOrganization.usersCountLimit}
                         onChange={this.handleAddOrgInputChange}/>
                  <label htmlFor="org-userLimit">User limit</label>
                  <i className="far fa-question-circle">
                    <div className="quesPopup">Value 0 means unlimited.</div>
                  </i>
                </div>
                <div className={this.getAddInputClass('videoMinutesLimit')}>
                  <input id="org-templateLimit" type="number" min="0" name="templatesCountLimit"
                         value={this.state.newOrganization.templatesCountLimit}
                         onChange={this.handleAddOrgInputChange}/>
                  <label htmlFor="org-templateLimit">Template limit</label>
                  <i className="far fa-question-circle">
                    <div className="quesPopup">Value 0 means unlimited.</div>
                  </i>
                </div>

                <div className={this.getAddInputClass('key')}>
                  <input id="org-key" type="text" name="key" value={this.state.newOrganization.key}
                         onChange={this.handleAddOrgInputChange}/>
                  <label htmlFor="org-key">Template key</label>
                  <i className="far fa-question-circle">
                    <div className="quesPopup"> TODO: ""</div>
                  </i>

                </div>

                <div
                  className={this.state.newOrganization.templateName && this.state.newOrganization.templateName.length !== undefined && this.state.newOrganization.templateName.length > 1 ? 'form-label-input-block valid-field' : 'form-label-input-block'}>
                  <input id="org-nam" type="text" name="templateName"
                         value={this.state.newOrganization.templateName}
                         onChange={this.handleAddOrgInputChange}/>
                  <label htmlFor="templateName"> Template name</label>
                </div>

                <div className={this.getAddInputClass('webUrl')}>
                  <input id="org-url" type="text" name="webUrl" value={this.state.newOrganization.webUrl}
                         onChange={this.handleAddOrgInputChange}/>
                  <label htmlFor="org-url">Showcase URL</label>
                  <i className="far fa-question-circle">
                    <div className="quesPopup">URL to external web</div>
                  </i>
                            </div>




                            {this.state.newOrganization.ctaAvailable && <div className={this.getAddInputClass('ctaUrl')}>
                                <input id="org-cta" type="text" name="ctaUrl" value={this.state.newOrganization.ctaUrl}
                                    onChange={this.handleAddOrgInputChange} />
                                <label htmlFor="org-cta">CTA URL</label>
                                <i className="far fa-question-circle">
                                    <div className="quesPopup">URL for Click-to-action</div>
                                </i>
                            </div>}

                            {this.state.newOrganization.ctaAvailable && <div className={this.getAddInputClass('ctaUrl')}>
                                <Switch updateValue={this.handleCtaValueChanged} state={this.state.newOrganization.ctaVisible} id="ctaVisible" />
                                <label>CTA Visible</label>
                            </div>}


                <div className="form-label-input-block">
                  <div>
                    <button className={this.getAddKeyBtnClass()} onClick={this.orgAddNewKey}><i
                      className="fas fa-plus"></i>Add
                    </button>
                    {this.state.newOrganization.templates.map(template =>
                      <div key={template.id}>
                        <button type="button" className="remove-template" data-id={template.id}
                                onClick={this.orgAddDeleteKey}><i className="far fa-times-circle"></i>
                        </button>
                        {template.name}</div>,
                    )}
                  </div>
                  <label htmlFor="org-key">Added templates</label>
                </div>
                <button disabled={!this.state.isAddValid}
                        className={this.getAddBtnClass()}>{this.resolveButtonLabel()}</button>
              </form>
            </div>
          </div>

        </section>
        }
        {this.state.isAuthenticated &&

        <section className="section-layout">
          <h1 className="section-header-1">Organizations</h1>
          {organizationsContent}
        </section>
        }
      </div>
    )
  }
}