import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../Resources/Stylesheets/General/popup.css';
import Autocomplete from 'react-google-autocomplete';

export function RenderPlaceInput(inputInfo, instance) {
    return (
        <div className={instance.getInputClass(inputInfo.key)} key={inputInfo.key} item={inputInfo}>
            <Autocomplete
                placeholder=""
                onPlaceSelected={(place) => {
                    place["target"] = {};
                    place["target"]["type"] = "place";
                    place["target"]["name"] = inputInfo.key;
                    instance.handleInputChange(place);
                }}
                types={['geocode']}
                required={inputInfo.required}
            />
            <label htmlFor={inputInfo.key}>{inputInfo.label}</label>
        </div>
    );
}