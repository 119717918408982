import React, {useEffect,useState} from "react"
import {Header, Title, MenuWrapper, NotificationItemWrapper} from './styled'
import { Link } from 'react-router-dom'
import * as routes from '../../../../routes'
import NotificationItem from './NotificationItem'
import { getNotifications } from '../../../Services/Notification/get-notifications'
import { markAsRead } from '../../../Services/Notification/mark-as-read'
import { markAsSeen } from '../../../Services/Notification/mark-as-seen'

import { HubConnectionBuilder } from '@aspnet/signalr'
import { GetVideosById } from '../../../Services/VideoServices/VideoByIdService'
import OutsideAlerter from '../../OutsideClick'

const NotificationCenter = ({data, isLoadingData, onAllRead, onReadMessage, dropMenu}) => {
  const [isLoading, setIsLoading] = useState(isLoadingData)

const handleMarkAllAsRead = async () => {
  let ids = []
  data.map(item => ids.push(item.id))
  markAsRead({
    id: ids
  }).then(() => setIsLoading(true))
  onAllRead()
}

  return(
    <MenuWrapper>
      <OutsideAlerter dropMenu={() => dropMenu()}>
      <Header>
        <Title>
          Notifications
        </Title>
        <div onClick={handleMarkAllAsRead} style={{color: "#33B58F", cursor: "pointer"}}>
          Clear notifications
        </div>
      </Header>
      <NotificationItemWrapper style={{overflowY: "auto", borderRadius: "8px"}}>
        {isLoading && ""}
        {!isLoading &&
        data.map(ntfItem => <NotificationItem key={ntfItem.id} onReadMessage={onReadMessage} data={ntfItem}/>)
        }
        {!isLoading && data.length === 0 &&
        <div style={{paddingBottom: "16px"}}>You don´t have any notifications</div>
        }
      </NotificationItemWrapper>
      </OutsideAlerter>
    </MenuWrapper>
  )
}
export default NotificationCenter