import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Header } from '../Components/General/Header';
import { Footer } from '../Components/General/Footer'

const MenuLayout = ({ children, ...rest }) => (
    <div>
        <main id="main">
            <header>
                <Header backBtn="true" {...rest} />
            </header>
            {children}
        </main>
        <Footer />
    </div>
);

const MenuLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <MenuLayout {...rest}>
                <Component {...matchProps} />
            </MenuLayout>
        )} />
    )
};

export default MenuLayoutRoute; 