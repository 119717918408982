import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import cookie from 'react-cookies';
import '../Resources/Stylesheets/LogIn/signin.css';
import { LoginAuth } from '../Services/LoginService';
import jwt from 'jsonwebtoken';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import '../Resources/Stylesheets/General/popup.css';
import { RenderLoader } from '../Components/General/Loader';

export class LogIn extends Component {
    static displayName = LogIn.name;

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            redirect: false,
            redirectOnetime: false,
            logging: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentWillMount() {
        document.title = "Sign In | Personalizard";
        if (sessionStorage.getItem("userData")) {
            if (cookie.load("isOneTimePassword"))
                this.setState({ redirectOnetime: true });
            else
                this.setState({ redirect: true });
        }
        this.timer = setInterval(() => {
            var autofilled = document.querySelectorAll('input:-webkit-autofill');
            for (let i = 0; i < autofilled.length; i++) $(autofilled[i]).parent().children("label").addClass("input-filled")
        }, 33);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.checkInputLabels(event.target.name);
    }

    handleSubmit(event) {
        if (this.state.email && this.state.password) {
            const userData = {
                "Email": this.state.email,
                "Password": this.state.password
            };
            this.setState({ logging: true });
            LoginAuth(userData, this);                
        }
        else 
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1><i className="fas fa-exclamation-triangle"/>Sign in warning!</h1>
                            <p>Please fill in all required fields.</p>
                            <div className="line"></div>
                            <button className="yes" onClick={onClose}>Ok</button>
                        </div>
                    );
                }
            });
        event.preventDefault();
    }

    checkInputLabels(name) {
        let element = $("#user-" + name);
        if (element.val() != "")
            element.parent().children("label").addClass("input-filled");
        else
            element.parent().children("label").removeClass("input-filled");
    }

    render() {
        let mainStyle = { paddingTop: "10%"};
        if (this.state.redirect) return (<Redirect to={'/dashboard'} />);
        if (this.state.redirectOnetime) return (<Redirect to={'/onetime-password'} />);
        if (this.state.logging) return RenderLoader();        

        return (
            <main id="main" style={ mainStyle }>
                <div className="signin-main-logo-block">
                    <img className="signin-main-logo" alt="" src="./Resources/Images/General/Personalizard.png" />
            </div>
                <div className="signin-form">
                    <form onSubmit={this.handleSubmit}>
                        <div className="adv-form-label-input-block">
                            <input id="user-email" type="email" name="email" value={this.state.email} onChange={this.handleInputChange} />
                            <label htmlFor="user-email">Email</label>
                            <i className="fas fa-envelope"></i>
                    </div>
                        <div className="adv-form-label-input-block">
                            <input id="user-password" type="password" name="password" value={this.state.password} onChange={this.handleInputChange} />
                            <label htmlFor="user-password">Password</label>
                            <i className="fas fa-key"></i>
                    </div>
                        <button className="form-submit-button form-submit-button-active">Sign in</button>
                </form>
                </div>
                </main>
        );
    }
}