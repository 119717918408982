import config from './../../../config'
const baseUrl = config.baseUrl + '/Templates'

export const editTemplate = async ({data}) => {
  await fetch(baseUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + sessionStorage.getItem("userData")
    },
    body: JSON.stringify(data)
  })
}