import React from 'react'

const baseUrl = window.location.origin + /video/


export const ShareByFacebook = ({ id, icon, picture, title }) => {
  return (
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${baseUrl + id}`} target="_blank">
        {icon && icon}
      </a>
  )
}

export const ShareByTwitter = ({ id, icon }) => {
  return <a
    href={`http://twitter.com/share?url=${baseUrl}${id}&text=Truly personalized video experience&hashtags=personalizard,personalizedvideo`}
    target="_blank">
    {icon && icon}
  </a>
}

export const ShareByLinkedIn = ({ id, icon, picture, title }) => {
  return <a
    href={`https://www.linkedin.com/shareArticle?mini=true&url=${baseUrl}${id}`}
    target="_blank">
    {icon && icon}
  </a>
}
