import config from './../../../config'
const baseUrl = config.baseUrl + '/Notification/'

export const getNotifications = async () => {
  const response = await fetch(baseUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + sessionStorage.getItem("userData")
    },
  })
  return await response.json()
}