import styled from "styled-components"

export const ItemWrapper = styled.div`
  margin: 8px;
  height: 80px;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  background-color: ${props => props.isSeen ? "white" : "#fafafa"}
  :hover {
    background-color: ${props => props.isSeen ?  "#fafafa" : "#fff3f2"} 
  };
   :focus {
    background-color: ${props => props.isSeen ?  "white" : null} 
  };
  
  
`

export const Icon = styled.i`
    align-self: center;
    font-size: 32px;
    padding-left: 12px;
`
export const Time = styled.div`
    padding-right: 16px;
    padding-top: 8px;
    font-size: 12px;
    font-weight: bold;
    color: gray;
`

export const Text = styled.div`
    font-size: 14px;
`