import React, {useState, useEffect} from "react"
import {logsList} from "./../../../../Services/Logs/get-logs"
import UserLog from "./UserLog"
import { Row } from "./styled"
import {PaginationWrap} from './styled'

const LogList = () => {
  const [data,setData] = useState(null)
  const [pagination, setPagination] = useState(1)
  useEffect(() => {
    async function fetchData() {
     const _data = await logsList({
       pagination: pagination
     })
      setData(_data)
    }
    fetchData();

  }, [pagination]);

  const handlePagination = (event) => {
    switch (event.target.id) {
      case "newer":
        if (pagination !== 1) {
          setPagination(pagination - 1)
        }
        break;
      case "older":
        if (pagination < data.countAll / 20) {
          setPagination((pagination + 1))
        }
      default:
        break
    }
  }

  return (
    <div>
      <h1 style={{paddingBottom: "8px"}}>User Login history</h1>
      <table style={{width:" 100%"}}>
        <tbody>
        <Row>
          <th>
            DATE
          </th>
          <th>
            USER
          </th>
          <th>
            LOCATION
          </th>
        </Row>
        { data &&
        data.logs.length > 0 ? data.logs.map(userLog => <UserLog key={userLog.id} userLogData={userLog}/> ) : null}
        {data &&
        <Row style={{borderBottom: "0px", marginTop: "8px"}}>
          <td>
            <PaginationWrap onClick={(e) => handlePagination(e)} id="newer" IsEnable={pagination > 1}>
              Newer
            </PaginationWrap>
          </td>
          <td>

          </td>
          <td>
            <PaginationWrap onClick={(e) => handlePagination(e)} id="older" IsEnable={pagination < data.countAll / 20}>
              Older
            </PaginationWrap>
          </td>
        </Row>
        }
        </tbody>
      </table>
    </div>
  )
}

export default LogList