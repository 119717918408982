import config from './../../../config'
const baseUrl = config.baseUrl + '/Support'

export const postSupport = async ({ data }) => {
    data.message = data.message.replace(/\n\r?/g, '<br />')
  await fetch(baseUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + sessionStorage.getItem("userData")
    },
    body: JSON.stringify(data)
  })
}