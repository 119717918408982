import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from 'jquery';
import { ValidateToken } from '../../Services/TokenValidityService';
import { Identity } from '../../Services/IdentityService';
import { Roles, GetRole } from '../../Services/RoleService';
import '../../Resources/Stylesheets/Settings/settings.css';
import { GetUserOverview } from '../../Services/UsersServices/GetUserOverviewService';
import { GetOrganizationLogo } from '../../Services/OrganizationServices/GetOrganizationLogoService';
import { ValidateEmail } from '../../Validators/EmailValidatorService';
import { ValidatePassword } from '../../Validators/PasswordValidatorService';
import { ValidateName } from '../../Validators/NameValidatorService';
import { EditUser } from '../../Services/UsersServices/EditUserService';
import { UploadLogo } from '../../Services/OrganizationServices/UploadLogoService';
import { ChangeOrganizationLogo } from '../../Services/OrganizationServices/ChangeOrganizationLogoService';
import { DeleteYourself } from '../../Services/UsersServices/DeleteYourselfService';
import { RenderLogoInput } from './Components/LogoInput';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';
import { RenderLoader } from '../../Components/General/Loader';

// Componenets
import NotificationForm from './../Settings/NotificationForm'
import SingleLog from "./../Settings/Logs/LastLog"
import LogList from './../Settings/Logs/LogList'

export class Settings extends Component {
    static displayName = Settings.name;

    constructor(props) {
        super(props);
        this.state = {
            identity: null,
            redirect: false,
            loading: true, 
            orgLoading: true,
            editUserValidators: {
                name: true,
                email: true,
                password: null,
                passwordAgain: null
            },
            isEditValid: false,
            userOverview: null,
            orgLogo: null,
            isUploading: false
        };
        this.handleEditUserInputChange = this.handleEditUserInputChange.bind(this);
        this.checkEditValidators = this.checkEditValidators.bind(this);
        this.getEditInputClass = this.getEditInputClass.bind(this);
        this.getEditBtnClass = this.getEditBtnClass.bind(this);
        this.editUser = this.editUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentWillMount() {
        ValidateToken();
        document.title = "Settings | Personalizard";
        if (sessionStorage.getItem("userData")) {
            const identity = Identity();
            GetUserOverview(this);
            if(GetRole(identity.role) === Roles.OrgAdmin)
            {
                GetOrganizationLogo(this, identity.organizationId);
            }
            this.setState({ identity: identity });
        }
        else {
            this.setState({ redirect: true });
        }
    }

    handleEditUserInputChange(event) {
        let userVals = this.state.userOverview;
        let validatorsVals = this.state.editUserValidators;
        switch (event.target.name) {
            case "name":
                validatorsVals.name = ValidateName(event.target.value);
                this.setState({ editUserValidators: validatorsVals });
                break;
            case "email":
                validatorsVals.email = ValidateEmail(event.target.value);
                this.setState({ editUserValidators: validatorsVals });
                break;
            case "oldPassword":
                validatorsVals.oldPassword = ValidatePassword(event.target.value);
                this.setState({ editUserValidators: validatorsVals });
                break;
            case "password":
                validatorsVals.password = ValidatePassword(event.target.value);
                this.setState({ editUserValidators: validatorsVals });
                break;
            case "passwordAgain":
                validatorsVals.passwordAgain = ValidatePassword(event.target.value);
                this.setState({ editUserValidators: validatorsVals });
                break;
        }
        userVals[event.target.name] = event.target.value;
        this.setState({ userOverview: userVals });
        this.checkEditValidators();
    }

    checkEditValidators() {
        const validators = this.state.editUserValidators;
        if (GetRole(this.state.identity.role) === Roles.User) {
            if (validators.name == true && validators.email == true && ((validators.password == true && validators.passwordAgain == true && validators.oldPassword == true) || (validators.password == null && validators.passwordAgain == null && validators.oldPassword == null)))
                if (this.state.userOverview.password == this.state.userOverview.passwordAgain) this.setState({ isEditValid: true });
                else this.setState({ isEditValid: false });
            else this.setState({ isEditValid: false });
        }
        else {
            if (validators.email == true && ((validators.password == true && validators.passwordAgain == true && validators.oldPassword == true) || (validators.password == null && validators.passwordAgain == null && validators.oldPassword == null)))
                if (this.state.userOverview.password == this.state.userOverview.passwordAgain) this.setState({ isEditValid: true });
                else this.setState({ isEditValid: false });
            else this.setState({ isEditValid: false });
        }
    }

    openFileChooser(event) {
        $("#" + event.currentTarget.dataset.id).click();
        event.preventDefault();
    }

    handleLogoInputChange(instance, e) {
        if (e.target.files.length > 0) {
            let oldOrgLogo = this.state.orgLogo;
            this.setState({ isUploading: true, orgLogo: e.target.files[0].name });
            UploadLogo(this, e.target.files[0], this.state.identity.organizationId, oldOrgLogo);
        }
    }
    removeImage(instance, e) {
        ChangeOrganizationLogo("", instance.state.identity.organizationId, instance);
    } 

    getEditInputClass(name) {
        if (this.state.editUserValidators[name] == null) return ("form-label-input-block");
        else if (this.state.editUserValidators[name] == false) return ("form-label-input-block nonvalid-field");
        else return ("form-label-input-block valid-field");
    }

    getEditBtnClass() {
        if (this.state.isEditValid) return ("form-submit-button form-submit-button-active");
        else return ("form-submit-button");
    }

    editUser(event) {
        if (this.state.isEditValid) {
            let userVals = this.state.userOverview;
            userVals.id = this.state.identity.userId;
            EditUser(userVals, this, false, false, true);
            this.setState({
                isEditValid: false, editUserValidators: {
                    name: true,
                    email: true,
                    oldPassword: null,
                    password: null,
                    passwordAgain: null
                } 
            });
        }
        event.preventDefault();
    }

    renderFormWithName() {
        return (
            <form onSubmit={this.editUser}>
                <div className={this.getEditInputClass("name")}>
                    <input id="user-name" type="text" name="name" value={this.state.userOverview.name} onChange={this.handleEditUserInputChange} required />
                    <label htmlFor="user-name" name="name">Name</label>
                </div>
                <div className={this.getEditInputClass("email")}>
                    <input id="user-email" name="email" type="email" value={this.state.userOverview.email} onChange={this.handleEditUserInputChange} required />
                    <label htmlFor="user-email">Email</label>
                </div>
                <div className={this.getEditInputClass("oldPassword")}>
                    <input id="user-old-password" name="oldPassword" type="password" value={this.state.userOverview.oldPassword} onChange={this.handleEditUserInputChange} />
                    <label htmlFor="user-old-password">Old password</label>
                </div>
                <div className={this.getEditInputClass("password")}>
                    <input id="user-password" name="password" type="password" value={this.state.userOverview.password} onChange={this.handleEditUserInputChange} />
                    <label htmlFor="user-password">Password</label>
                    <i className="far fa-question-circle"><div className="quesPopup">At least 6 chars</div></i>
                </div>
                <div className={this.getEditInputClass("passwordAgain")}>
                    <input id="user-password-again" name="passwordAgain" type="password" value={this.state.userOverview.passwordAgain} onChange={this.handleEditUserInputChange} />
                    <label htmlFor="user-password-again">Password again</label>
                </div>
                <button disabled={!this.state.isEditValid} className={this.getEditBtnClass()}>Save</button>
            </form>
        );
    }

    renderFormWithoutName() {
        return (
            <form onSubmit={this.editUser}>
                <div className={this.getEditInputClass("email")}>
                    <input id="user-email" name="email" type="email" value={this.state.userOverview.email} onChange={this.handleEditUserInputChange} required />
                    <label htmlFor="user-email">Email</label>
                </div>
                <div className={this.getEditInputClass("oldPassword")}>
                    <input id="user-old-password" name="oldPassword" type="password" value={this.state.userOverview.oldPassword} onChange={this.handleEditUserInputChange} />
                    <label htmlFor="user-old-password">Old password</label>
                </div>
                <div className={this.getEditInputClass("password")}>
                    <input id="user-password" name="password" type="password" value={this.state.userOverview.password} onChange={this.handleEditUserInputChange} />
                    <label htmlFor="user-password">Password</label>
                    <i className="far fa-question-circle"><div className="quesPopup">At least 6 chars</div></i>
                </div>
                <div className={this.getEditInputClass("passwordAgain")}>
                    <input id="user-password-again" name="passwordAgain" type="password" value={this.state.userOverview.passwordAgain} onChange={this.handleEditUserInputChange} />
                    <label htmlFor="user-password-again">Password again</label>
                </div>
                <button className={this.getEditBtnClass()}>Save</button>
            </form>
        );
    }

    renderRoleInfo() {
        if (GetRole(this.state.identity.role) === Roles.SysAdmin) return (<p>You are God of <span>Personalizard</span></p>);
        else if (GetRole(this.state.identity.role) === Roles.OrgAdmin) return (<p>You are a administrator of <span>{this.state.identity.organizationName}</span></p>);
        else return (<p>You are a member of <span>{this.state.identity.organizationName}</span></p>);
    }

    renderDeleteButton() {
        return (<p className="settings-remove-account">I don´t want to be a user of Personalizard anymore. <button onClick={this.deleteUser}>Delete my account.</button></p>);
    }

    renderOrganizationSetting() {
        return (
            <div style={{ marginTop: "40px" }} className="settings-edit-block">
                <h1>Edit organization</h1>
                {RenderLogoInput(this)}
            </div>
        );
    }

    deleteUser(event) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1><i className="fas fa-exclamation-triangle"></i>Delete warning!</h1>
                        <p>This action is permanent. Would you like to proceed?</p>
                        <div className="line"></div>
                        <button className="yes"
                            onClick={() => {
                                DeleteYourself(this.state.identity.userId, this);
                                onClose();
                            }}
                        >
                            <i class="far fa-trash-alt"></i>Yes
                        </button>
                        <button className="no" onClick={onClose}>No</button>
                    </div>
                );
            }
        });
        event.preventDefault();
    }

    render() {
        if (this.state.redirect) return (<Redirect to={'/login'} />);
        let loader = this.state.loading ? RenderLoader() : ""; 
        let settingsForm = "";
        var orgSettingsForm = "";
        if (!this.state.loading) settingsForm = GetRole(this.state.identity.role) !== Roles.SysAdmin ? this.renderFormWithName() : this.renderFormWithoutName();
        if (!this.state.orgLoading) orgSettingsForm = GetRole(this.state.identity.role) === Roles.OrgAdmin ? this.renderOrganizationSetting() : "";
        let deleteAccount = GetRole(this.state.identity.role) === Roles.User ? this.renderDeleteButton() : "";
        return (
            <main className="main-content block-layout">
                {loader}
                <div className="settings-edit-block">
                    <h1>Edit myself</h1>
                    {this.renderRoleInfo()}
                    {settingsForm}
                </div>
                {orgSettingsForm}
                {deleteAccount}
             <SingleLog/>
                {GetRole(this.state.identity.role) === Roles.OrgAdmin &&
                <div className="settings-edit-block" style={{marginTop: "16px", backgroundColor: "white", padding: "0", paddingBottom: "40px"}}>
                  <LogList/>
                </div>}
            </main>
        );
    }
}