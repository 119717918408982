import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../Resources/Stylesheets/General/popup.css';

export function RenderEnumInput(inputInfo, instance) {
    return (

        <div className="form-label-input-block" key={inputInfo.key} item={inputInfo}>
            <select id={inputInfo.key} name={inputInfo.key} onChange={instance.handleInputChange} required={inputInfo.required}>
                {Object.keys(inputInfo.options).map(fieldKey => renderOption(inputInfo.options[fieldKey]))}
            </select>
            <label htmlFor={inputInfo.key}>{inputInfo.label}</label>
        </div>
    );
}

function renderOption(label) {
    return (<option key={label.value} value={label.value}>{label.name}</option>);
}