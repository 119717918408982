import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import $ from 'jquery';
import { ValidateToken } from '../Services/TokenValidityService';
import { GetVideoTemplate } from '../Services/VideoServices/LoadVideoTemplateService';
import '../Resources/Stylesheets/NewVideo/newvideo.css';
import { GetTemplatesByOrganization } from '../Services/TemplatesServices/TemplatesByOrganizationService';
import { GetTemplateDetail } from '../Services/TemplatesServices/GetTemplateDetail';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../Resources/Stylesheets/General/popup.css';
import * as AComplete from 'react-autocomplete';
import { RenderLoader } from '../Components/General/Loader';
import image from "./../Resources/Images/video-preview.png"

// Importing input components
import { RenderPlaceInput } from '../Components/Video/Inputs/PlaceInput';
import { RenderNumberInput } from '../Components/Video/Inputs/NumberInput';
import { RenderImageInput } from '../Components/Video/Inputs/ImageInput';
import { RenderEnumInput } from '../Components/Video/Inputs/EnumInput';
import { RenderBooleanInput } from '../Components/Video/Inputs/BooleanInput';
import { RenderTextInput } from '../Components/Video/Inputs/TextInput';
import { RenderPageInput } from '../Components/Video/Inputs/PageInput';
import { UploadImage } from '../Services/UploadImageService';
import { GenerateVideo } from '../Services/VideoServices/GenerateVideoService';
import { ValidateText } from '../Validators/TextValidatorService';


export class NewVideo extends Component {
    static displayName = NewVideo.name;

    constructor(props) {
        super(props);
        this.state = {
            templates: null,
            loading: true,
            redirect: false,
            templateSelected: false,
            template: null,
            templateLoaded: false,
            videoName: "",
            isNameValid: null,
            selectedItem: null,
            isSelectMenuDrop: false,
            inputs: null,
            validators: null,
            redirectVideos: false,
            isAllValid: false,
        };
        this.generateVideo = this.generateVideo.bind(this);
        this.openFileChooser = this.openFileChooser.bind(this);
        this.openImage = this.openImage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.getInputClass = this.getInputClass.bind(this);
        this.getNameInputClass = this.getNameInputClass.bind(this);
        this.checkValidators = this.checkValidators.bind(this);
        this.dropSelectMenu = this.dropSelectMenu.bind(this)
    }

    componentWillMount() {
        ValidateToken();
        document.title = "New video | Personalizard";
        if (sessionStorage.getItem("userData")) {
            GetTemplatesByOrganization(this);
        }
        else {
            this.setState({ redirect: true });
        }
    }

    static renderInput(input, instance) {
        switch (input.type) {
            case "place":
                return RenderPlaceInput(input, instance);
                break;
            case "number":
                return RenderNumberInput(input, instance);
                break;
            case "image":
                return RenderImageInput(input, instance);
                break;
            case "enum":
                return RenderEnumInput(input, instance);
                break;
            case "boolean":
                return RenderBooleanInput(input, instance);
                break;
            case "text":
                return RenderTextInput(input, instance);
                break;
            case "page":
                if (input.key == "cta" && !instance.state.template.ctaVisible && instance.state.template.ctaUrl != "") return "";
                return RenderPageInput(input, instance);
                break;
            default:
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Fetch warning!</h1>
                                <p>Can´t render template input of type {input.type} - {input.label}</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
        }
    }

    static renderTemplateComponents(instance) {
        if (instance.state.template != null) {
            let btnClass = instance.state.isAllValid ? "form-submit-button form-submit-button-active" : "form-submit-button";
            let fields = instance.state.template.fields;
            return (
                <form onSubmit={instance.generateVideo}>
                    <div className="colored-layout">
                        <h1>Parameters</h1>
                        <div className={instance.getNameInputClass()} key="videoName">
                            <input id="videoName" type="text" name="videoName" value={instance.state.videoName} onChange={instance.handleNameChange} required={true} />
                            <label htmlFor="videoName">Video name</label>
                        </div>
                        {fields.map(field => NewVideo.renderInput(field, instance))}
                    </div>
                    <div className="save-button">
                        <button className={btnClass}>Generate video</button>
                    </div>
                </form>
            );
        } return "";
    }

    handleNameChange(e) {
        this.setState({ videoName: e.target.value, isNameValid: ValidateText(e.target.value) });
        this.checkValidators();
    }

    handleInputChange(e) {
        let inputs = this.state.inputs;
        let validators = this.state.validators;
        if (e.target.type == "place") {
            inputs[e.target.name] = e;
            validators[e.target.name].isValid = this.state.validators[e.target.name].validate(e, validators[e.target.name].isRequired);
        }
        else if (e.target.type == "file") {
            if (e.target.files.length > 0) {
                inputs[e.target.name]["name"] = e.target.files[0].name;
                inputs[e.target.name]["file"] = e.target.files[0];
                inputs[e.target.name]["isUploading"] = true
                UploadImage(this, e.target.name, e.target.files[0]);
            } else {
                validators[e.target.name].isValid = (validators[e.target.name].isRequired ? null : true);
            }
        }
        else if (e.target.type == "text" || e.target.type == "number" || e.target.type == "select-one" || e.target.type == "url") {
            inputs[e.target.name] = e.target.value;
            if (e.target.type == "text" || e.target.type == "number" || e.target.type == "url")
                validators[e.target.name].isValid = this.state.validators[e.target.name].validate(e.target.value, validators[e.target.name].isRequired);
        }
        else if (e.target.type == "radio") {
            inputs[e.target.name] = (e.target.value == "true");
        }
        this.setState({ inputs: inputs, validators: validators });
        this.checkValidators();
    }

    dropSelectMenu() {
        this.setState(prevState => ({
            isSelectMenuDrop: !prevState.isSelectMenuDrop
        }))
    }

    selectItem(selectedItem) {
        this.setState({ selectedItem: selectedItem, templateSelected: true, isSelectMenuDrop: false  });
        GetTemplateDetail(selectedItem.key, this)
    }
    openFileChooser(event) {
        let validators = this.state.validators;
        validators[event.currentTarget.dataset.key].isValid = false;
        this.setState({ validators: validators });
        this.checkValidators();
        $("#" + event.currentTarget.dataset.id).click();
        event.preventDefault();
    }

    openImage(event) {
        window.open(this.state.inputs[event.currentTarget.dataset.id].href, "_blank");
    }

    removeImage(event) {
        const key = event.currentTarget.dataset.id;
        let inputs = this.state.inputs;
        inputs[key] = { name: "No picture", file: null };
        this.setState({ inputs });
    }

    checkValidators() {
        let isValid = true;
        Object.keys(this.state.validators).map((value, index, array) => {
            isValid &= this.state.validators[value].isValid;
        });
        isValid &= this.state.isNameValid;
        isValid = isValid ? true : false
        this.setState({ isAllValid: isValid });
    }

    generateVideo(event) {
        if (this.state.isAllValid) {
            this.setState({ loading: true });
            GenerateVideo(this);
        }
        event.preventDefault();
    }

    getTemplates() {
        return this.state.templates;
    }

    getNameInputClass() {
        if (this.state.isNameValid == null) return "form-label-input-block";
        if (this.state.isNameValid == true) return "form-label-input-block valid-field";
        else return "form-label-input-block nonvalid-field";
    }

    getInputClass(input) {
        if (this.state.validators[input].isValid == null) return "form-label-input-block";
        if (this.state.validators[input].isValid == true) return "form-label-input-block valid-field";
        else return "form-label-input-block nonvalid-field";
    }

    render() {
        if (this.state.redirect) return (<Redirect to={'/login'} />);
        if (this.state.redirectVideos) return (<Redirect to={'/my-videos'} />);

        let loader = this.state.loading ? RenderLoader() : "";
        let templateContents = "";
        if (this.state.templateSelected) templateContents = !this.state.templateLoaded
            ? <div className="items-block"><div className="items-message">Loading...</div></div>
            : NewVideo.renderTemplateComponents(this);

        return (
            <main className="main-content block-layout">
                {loader}
                <section className="section-layout">
                    <div className="colored-layout">
                        <h1>Select template</h1>
                        {this.state.templates !== null && this.state.templates.length == 0 && <div>No templates found</div>}
                        {this.state.templates !== null && this.state.templates.length > 0 &&
                            <div className="form-label-input-block">
                                <div onClick={() => this.dropSelectMenu()} style={{width: "100%", height: "40px", backgroundColor: "white", borderRadius: "8px", border: "1px solid lightgrey", cursor: "pointer"}}>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <div style={{padding: "8px", fontSize: "15px"}}>
                                            {this.state.selectedItem === null ? "Select from templates" : this.state.selectedItem.name}
                                        </div>
                                        <i style={{position: "unset", fontSize:"15px", paddingRight: "8px"}} className="fas fa-caret-down"/>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.isSelectMenuDrop &&
                        <div className={"select-div"}>
                            {this.getTemplates().map(item => <div className={"selected-item"} key={item.key} value={item.name} onClick={() => this.selectItem(item)}>

                                <div style={{ display: "flex", alignItems: "center" }}>
                                        <img height="50px" src={item.thumbnail === "about:blank" ? image : item.thumbnail}/>
                                        <div style={{ paddingLeft: "16px" }}>{item.name}</div>
                                    </div>
                                    <span>{item.email}</span>
                            </div>)}
                        </div>
                        }
                        {this.state.template !== null &&
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div style={{ paddingRight: "16px" }}>
                                    < img height="50px" src={this.state.template.thumbnail === "about:blank" ? image : this.state.template.thumbnail}/>
                           </div>
                            <div>
                                {this.state.template.name}<br/>
                                {this.state.template.webUrl &&
                                <a href={this.state.template.webUrl} target="_blank">View detail</a>
                                }
                            </div>
                        </div>
                        }
                    </div>

                    {templateContents}
                </section>
            </main>
        );
    }
}