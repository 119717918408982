import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../Resources/Stylesheets/General/popup.css';

export function UploadImage(instance, target, file) {
    var data = new FormData();
    data.append("file", file);
    $.ajax('/api/Image', {
        type: 'POST',
        async: true,
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        success: function (output, status, xhr) {
            if (status == "success") {
                let inputs = instance.state.inputs;
                inputs[target]["href"] = xhr.getResponseHeader('Location');
                let validators = instance.state.validators; 
                validators[target].isValid = true;
                inputs[target].isUploading = false
                instance.setState({ inputs: inputs, validators: validators});
                instance.checkValidators();
                
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus == "error") {
                let validators = instance.state.validators;
                validators[target].isValid = validators[target].isRequired ? null : true;
                let inputs = instance.state.inputs;
                inputs[target].isUploading = false
                instance.setState({ inputs: inputs, validators: validators });

                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Upload warning!</h1>
                                <p>Error while uploading image.</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
            }
        }
      
    });
}