import styled from "styled-components"

export const IconWrapper = styled.div`
    border-radius: 100%;
    border: 2px solid #33B58F;
    height: 34px;
    width: 34px;
    text-align: center;
    line-height: 30px;
    margin-left: 4px;
`
export const Column = styled.div`
    width: 50%;
   @media (max-width: 768px) {
   width: 100%;
}
`

export const ColumnRight = styled.div`
    width: 50%;
    text-align: right;
   @media (max-width: 768px) {
   width: 100%;
   text-align: left;
}
`

export const WrapContent = styled.div`
    display: flex;
    justify-content: space-between;
   @media (max-width: 768px) {
   display: block;
}
`



export const Icon = styled.i`
    color: #33B58F;
`

export const SharePanel = styled.div`
  a {
    cursor: pointer;
  }
`

export const SharePanelVideoDetail = styled.div`
display: flex;
flex-direction: row-reverse;
  @media (max-width: 768px) {
   justify-content: flex-end;
   padding-top: 5px;
}
  a {
    cursor: pointer;
  }
`
