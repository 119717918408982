import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';

export function GenerateVideo(instance) {
    let video = {
        name: "",
        templateKey: "",
        fields: []
    };
    let inputs = instance.state.inputs;
    let template = instance.state.template;
    console.log(inputs)
    console.log(template)
    video.name = instance.state.videoName; 
    video.templateKey = template.key; 
    template.fields.map((value, index, array) => {
        if (value.type == "boolean" || value.type == "number" || value.type == "text" || value.type == "page") {
            video.fields.push({ key: value.key, value: inputs[value.key] });
        } else if (value.type == "enum") {
            video.fields.push({ key: value.key, value: inputs[value.key] });
        } else if (value.type == "place") {
            video.fields.push({ key: value.key, value: inputs[value.key]["formatted_address"] });
        } else if (value.type == "image") {
            video.fields.push({ key: value.key, value: inputs[value.key]["href"] });
        }
    });
    $.ajax('/api/Videos/', {
        type: 'POST',
        async: true,
        data: JSON.stringify(video),
        contentType: 'application/json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        success: function (output, status, xhr) {
            if (status == "success") {
                instance.setState({ redirectVideos: true });
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus == "error") {
                instance.setState({ loading: false });
                let text = "Error while sending request."; 
                if (jqXHR.status == 599) text = "You have exceeded the video generation limit."; 
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Process warning!</h1>
                                <p>{text}</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
            }
        }
    });
}