import styled from "styled-components"

export const Row = styled.tr`
  font-size: 13px;
  font-weight: normal;
  border-bottom: 2px solid #fafafa;
`

export const PaginationWrap = styled.div`
    color: ${props => !props.IsEnable ? "#fafafa" : "#33B58F"}
    &:hover {
      cursor: pointer;
    }

`