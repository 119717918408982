import config from './../../../config'
const baseUrl = config.baseUrl + '/Users/'

export const getUserById = async (id) => {
    const response = await fetch(baseUrl + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + sessionStorage.getItem("userData")
        } 
    })
    return await response.json();
}