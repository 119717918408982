import React from "react"
import moment from 'moment';
import { Row } from "./../styled"

const UserLog = ({userLogData}) => {
  return (
    <Row>
    <td>
      {moment(userLogData.logDate).format("YYYY/M/D HH:mm")}
    </td>
    <td>
      {userLogData.user.email}
    </td>
    <td>
      {userLogData.location}
    </td>
  </Row>
  )
}

export default UserLog