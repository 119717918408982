import React, {useState, useEffect} from "react"
import "../../../Components/FormComponents/Switch/index.css"
import Switch from "./../../../Components/FormComponents/Switch"
import "./index.css"
import { getNotificationSettings } from '../../../Services/Notification/get-notification-settings'
import { updateNotificationSettings } from '../../../Services/Notification/update-notification-settings'

const NotificationForm = () => {
  const [data, setData] = useState({
    isRenderedVideoNotifOn: null,
    isRenderedVideoEmailOn: null,
    isFirstSeenVideoNotifOn: null,
    isFirstSeenVideoEmailOn: null,
    isNewTemplateNotifOn: null,
    isNewTemplateEmailOn: null,
    isTemplateQuotaEmailOn: null,
    isTemplateQuotaNotifOn: null,
    isVideoQuotaNotifOn: null,
    isVideoQuotaEmailOn: null,
    minVideoQuota: 0,
    isUserQuotaNotifOn: null,
    isUserQuotaEmailOn: null
  })

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      const _data = await getNotificationSettings()
      setData(_data)
    }
    fetchData().then( () => setIsLoading(false));

  }, []);

  const handleValueChanged = async (id, value) => {
    setData({
      ...data,
      [id]: value
    })

    await updateNotificationSettings({
      data: {
        ...data,
        [id]: value
      }
      }
    )
  }

  return(
  <div>
    {!isLoading &&
    <table style={{ width: "100%" }}>
      <tbody>
      <tr>
        <th></th>
        <th>
          <div style={{ display: "flex", alignItems: "center" }}>
            <i className="far fa-bell"/>
            <div style={{ marginLeft: "8px", fontWeight: "600" }}>Push</div>
          </div>
        </th>
        <th></th>
        <th>
          <div style={{ display: "flex", alignItems: "center" }}>
            <i className="far fa-envelope"/>
            <div style={{ marginLeft: "8px", fontWeight: "600" }}>Mail</div>
          </div>
        </th>
      </tr>
      <tr>
        <td>Rendered Video</td>
        <td><Switch updateValue={handleValueChanged} state={data.isRenderedVideoNotifOn} id="isRenderedVideoNotifOn"/></td>
        <td></td>
        <td><Switch updateValue={handleValueChanged} state={data.isRenderedVideoEmailOn} id="isRenderedVideoEmailOn"/></td>
      </tr>
      <tr>
        <td>First View</td>
        <td><Switch updateValue={handleValueChanged} state={data.isFirstSeenVideoNotifOn} id="isFirstSeenVideoNotifOn"/></td>
        <td></td>
        <td><Switch updateValue={handleValueChanged} state={data.isFirstSeenVideoEmailOn} id="isFirstSeenVideoEmailOn"/></td>
      </tr>
      <tr>
        <td>Added new template</td>
        <td><Switch updateValue={handleValueChanged} state={data.isNewTemplateNotifOn} id="isNewTemplateNotifOn"/></td>
        <td></td>
        <td><Switch updateValue={handleValueChanged} state={data.isNewTemplateEmailOn} id="isNewTemplateEmailOn"/></td>
      </tr>
      <tr>
        <td>Template quota</td>
        <td><Switch updateValue={handleValueChanged} state={data.isTemplateQuotaNotifOn} id="isTemplateQuotaNotifOn" /></td>
        <td></td>
        <td><Switch updateValue={handleValueChanged} state={data.isTemplateQuotaEmailOn} id="isTemplateQuotaEmailOn" /></td>
      </tr>
      <tr>
        <td>User quota</td>
        <td><Switch updateValue={handleValueChanged} state={data.isUserQuotaNotifOn} id="isUserQuotaNotifOn"/></td>
        <td></td>
        <td><Switch updateValue={handleValueChanged} state={data.isUserQuotaEmailOn} id="isUserQuotaEmailOn"/></td>
      </tr>
      <tr>
        <td>Video quota</td>
        <td><Switch updateValue={handleValueChanged} state={data.isVideoQuotaNotifOn} id="isVideoQuotaNotifOn"/></td>
        <td></td>
        <td><Switch updateValue={handleValueChanged} state={data.isVideoQuotaEmailOn} id="isVideoQuotaEmailOn"/></td>
      </tr>
      {(data.isVideoQuotaEmailOn || data.isVideoQuotaNotifOn) &&
      <tr>
        <td>Percentage video quota</td>
        <td colSpan="3" style={{textAlign: "center"}}>
          <select id="minVideoQuota" value={data.minVideoQuota}
                  onChange={(e) => handleValueChanged("minVideoQuota", e.target.value)}
                  style={{
                    background: 'transparent',
                    borderColor: 'transparent',
                  }}>
            <option value={25}>25 %</option>
            <option value={50}>50 %</option>
            <option value={75}>75 %</option>
            <option value={80}>80 %</option>
            <option value={90}>90 %</option>
            <option value={100}>100 %</option>
          </select>
        </td>
      </tr>
      }
      </tbody>
    </table>
    }
  </div>
  )
}
export default NotificationForm