import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';
import { GetUsersAfterEdit } from './GetUserAfterEdit';
import { Identity } from '../IdentityService'; 
import { GetRole, Roles } from '../RoleService';
import { ValidatePassword } from '../../Validators/PasswordValidatorService';

export function EditUser(user, instance, isOrgUser = false, fetchAfterEdit = true, isEditMyself = false) {
    let editUser = {
        email: user.email,
        name: user.name
    };
    let identity = Identity();
    if (isEditMyself && (GetRole(identity.role) === Roles.SysAdmin)) {
        delete editUser.name;
    }
    if (ValidatePassword(user.password))
        editUser["password"] = user.password;
    $.ajax('/api/Users/' + user.id, {
        type: 'PUT',
        data: JSON.stringify(editUser),
        async: true,
        contentType: 'application/json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        success: function (output, status, xhr) {
            if (status == "success") { 
                if (user.oldPassword != "" && !isOrgUser) { 
                    $.ajax('/api/Auth/ChangePassword/', {
                        type: 'POST',
                        data: JSON.stringify({ oldPassword: user.oldPassword, newPassword: user.password }),
                        async: true,
                        contentType: 'application/json',
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
                        },
                        success: function (output, status, xhr) { 
                            if (status == "success") {
                                if (fetchAfterEdit) GetUsersAfterEdit(instance);
                                if (isEditMyself) {
                                    let userVals = instance.state.userOverview;
                                    userVals.oldPassword = "";
                                    userVals.password = "";
                                    userVals.passwordAgain = "";
                                    instance.setState({ userOverview: userVals });
                                }
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            if (textStatus == "error")
                                confirmAlert({
                                    customUI: ({ onClose }) => {
                                        return (
                                            <div className='custom-ui'>
                                                <h1><i className="fas fa-exclamation-triangle"></i>Process warning!</h1>
                                                <p>Error while changing password.</p>
                                                <div className="line"></div>
                                                <button className="yes" onClick={onClose}>Ok</button>
                                            </div>
                                        );
                                    }
                                });
                        }
                    });
                }
                if (fetchAfterEdit) GetUsersAfterEdit(instance);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus == "error")
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Process warning!</h1>
                                <p>Error while editing user.</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
        }
    });
}