import config from './../../../config'
const baseUrl = config.baseUrl + '/Notification/MarkAsSeen'

export const markAsSeen = async ({id}) => {
  await fetch(baseUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + sessionStorage.getItem("userData")
    },
    body: JSON.stringify(id)
  })
}