import React from "react"
import NotificationForm from '../NotificationForm'

const NotificationSettings = () => {
  return (
    <main className="main-content block-layout">
      <section className="section-layout">
        <div className="settings-edit-block" style={{ marginTop: "16px" }}>
          <h1>Notifications</h1>
          <NotificationForm/>
        </div>
      </section>
    </main>
  )
}
export default NotificationSettings