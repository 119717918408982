import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';
import { ChangeOrganizationLogo } from './ChangeOrganizationLogoService';

export function UploadLogo(instance, file, id, orgLogo) {
    if (file.name.includes(".png")) {
        var data = new FormData();
        data.append("file", file);
        $.ajax('/api/Image/logo', {
            type: 'POST',
            async: true,
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
            },
            success: function (output, status, xhr) {
                if (status == "success") {
                    ChangeOrganizationLogo(xhr.getResponseHeader('Location'), id, instance)
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (textStatus == "error") {
                    instance.setState({ orgLogo: null, isUploading: false });
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className='custom-ui'>
                                    <h1><i className="fas fa-exclamation-triangle"></i>Upload warning!</h1>
                                    <p>Error while uploading logo.</p>
                                    <div className="line"></div>
                                    <button className="yes" onClick={onClose}>Ok</button>
                                </div>
                            );
                        }
                    });
                }
            }

        });
    }
    else {
        instance.setState({ orgLogo: orgLogo, isUploading: false });
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1><i className="fas fa-exclamation-triangle"></i>Upload warning!</h1>
                        <p>Select .png image.</p>
                        <div className="line"></div>
                        <button className="yes" onClick={onClose}>Ok</button>
                    </div>
                );
            }
        });
    }
}