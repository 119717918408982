import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';

export function GetOrganizations(instance) {
    $.ajax('/api/Organizations', {
        type: 'GET',
        async: true,
        contentType: 'application/json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        success: function (output, status, xhr) {
            if (status === "success") {
                instance.setState({ organizations: output, loading: false });
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus === "error")
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"/>Fetch warning!</h1>
                                <p>Error while fetching organizations.</p>
                                <div className="line"/>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
        }
    });
}