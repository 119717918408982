import React from 'react';

export function RenderLoader() {
    return (
        <div className="motion-loader"><div className="bg-layer"></div>
            <div className="loader-info">
                <img src="/Resources/Images/General/Personalizard.png" />
                <p>Collecting data. Please wait...</p>
            </div>
        </div>
    );
}