import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from 'jquery';
import '../Resources/Stylesheets/Dashboard/dashboard.css';
import { Identity } from '../Services/IdentityService';
import { Roles, GetRole } from '../Services/RoleService';
import { ValidateToken } from '../Services/TokenValidityService';

export class Dashboard extends Component {
    static displayName = Dashboard.name;

    constructor(props) {
        super(props);
        this.state = {
            identity: null,
            redirect: false
        };
        this.renderMenuItems = this.renderMenuItems.bind(this);
        this.loadOrgadminMenuItems = this.loadOrgadminMenuItems.bind(this);
        this.loadSysadminMenuItems = this.loadSysadminMenuItems.bind(this);
        this.loadUserMenuItems = this.loadUserMenuItems.bind(this);
    }

    componentWillMount() {
        ValidateToken();
        document.title = "Dashboard | Personalizard";
        if (sessionStorage.getItem("userData")) {
            this.setState({ identity: Identity() });
        }
        else {
            this.setState({ redirect: true });
        }
    }

    loadSysadminMenuItems() {
        return (
            <main className="main-content block-layout">
                <div className="links-block">
                    <Link to={'/organizations'}><button className="horizontal-link-button" href="">
                        <i className="fas fa-user-friends"></i>
                        Organizations
        </button></Link>
                </div>
            </main>
        );
    }

    loadOrgadminMenuItems() {
        return (
            <main className="main-content block-layout">
                <Link to={'/new-video'}><button className="vertical-link-button" href="">
                    <i className="fas fa-plus"></i>
                    New video
    </button></Link>
                <div className="links-block">
                    <Link to={'/my-users'}><button className="horizontal-link-button" href="">
                        <i className="fas fa-user-friends"></i>
                        My users
        </button></Link>
                    <Link to={'/my-templates'}><button className="horizontal-link-button">
                        <i className="fas fa-mask"></i>
                        My templates
        </button></Link>
                    <Link to={'/my-videos'}><button className="horizontal-link-button" href="">
                        <i className="fas fa-video"></i>
                        My videos
        </button></Link>
                </div>
            </main>
        );
    }

    loadUserMenuItems() {
        return (
            <main className="main-content block-layout">
                <Link to={'/new-video'}><button className="vertical-link-button" href="">
                    <i className="fas fa-plus"></i>
                    New video
    </button></Link>
                <div className="links-block">
                    <Link to={'/my-videos'}><button className="horizontal-link-button" href="">
                        <i className="fas fa-video"></i>
                        My videos
        </button></Link>
                </div>
            </main>
        );
    }

    renderMenuItems() {
        let menuItems = null;
        const role = GetRole(this.state.identity.role)
        if (role === Roles.SysAdmin) {
            menuItems = this.loadSysadminMenuItems();
        } else if (role === Roles.OrgAdmin) {
            menuItems = this.loadOrgadminMenuItems();
        } else {
            menuItems = this.loadUserMenuItems();
        }
        return menuItems;
    } 

    render() {
        if (this.state.redirect) return (<Redirect to={'/login'} />);
        return this.renderMenuItems();
    }
}