import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../Resources/Stylesheets/General/footer.css'

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <footer>Copyright &copy; 2023 <a href="https://motionlab.io" target="_blank">Motionlab, s.r.o.</a></footer>
        );
    }
}