import styled from "styled-components"

export const Header = styled.div`
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  padding: 11px;
`

export const Title = styled.div`
  font-weight: bold;
`

export const MenuWrapper = styled.div`
  background-color: white;
  width: 25em;
  z-index: 10;
  text-align: center;
  position: absolute;
  top: 50px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,1);
  box-shadow: 0px 0px 1px 0px rgba(0,0,0,1);
  @media (max-width: 500px) {
   width: 90%;
   position: fixed;
   top: 66px;
  }
  
`

export const NotificationItemWrapper = styled.div`
    max-height: calc(50vh - 75px);
    @media (max-width: 500px) {
   max-height: calc(100vh - 75px);
  }
`