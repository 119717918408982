import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';
import { GetOrganizationsAfterEdit } from './GetOrganizationsAfterEdit';

export function AddNewOrganization(organization, instance) { 
    $.ajax('/api/Organizations', {
        type: 'POST',
        data: JSON.stringify(organization),
        async: true,
        contentType: 'application/json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        success: function (output, status, xhr) {
            if (status == "success") {
                GetOrganizationsAfterEdit(instance);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus == "error")
                instance.setState({ success: 2 });
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Process warning!</h1>
                                <p>Error while adding organization.</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
        }
    });
}