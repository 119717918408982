import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'
import { ValidateToken } from '../Services/TokenValidityService'
import '../Resources/Stylesheets/MyUsers/myusers.css'
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert'
import { AddNewUser } from '../Services/UsersServices/AddUserService'
import { GetUsersByOrganization } from '../Services/UsersServices/UsersByOrganizationService'
import { DeleteUserById } from '../Services/UsersServices/DeleteUserByIdService'
import { GetRole, Roles } from '../Services/RoleService'
import { Identity } from '../Services/IdentityService'
import { ValidateEmail } from '../Validators/EmailValidatorService'
import { ValidatePassword } from '../Validators/PasswordValidatorService'
import { ValidateName } from '../Validators/NameValidatorService'
import { EditUser } from '../Services/UsersServices/EditUserService'
import { RenderLoader } from '../Components/General/Loader'
import NoAuthenticated from './NoAuthenticated'

export class MyUsers extends Component {
  static displayName = MyUsers.name

  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      loading: true,
      success: 2,
      users: [],
      identity: null,
      isAddValid: false,
      editUser: null,
      isEditValid: false,
      isAuthenticated: false,
      newUser: {
        name: '',
        email: '',
        password: '',
      },
      addUserValidators: {
        name: null,
        email: null,
        password: null,
      },
      editUserValidators: {
        name: null,
        email: null,
        password: null,
      },
    }
    this.handleAddUserInputChange = this.handleAddUserInputChange.bind(this)
    this.handleEditUserInputChange = this.handleEditUserInputChange.bind(this)
    this.addUser = this.addUser.bind(this)
    this.editUser = this.editUser.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.toggleAddUser = this.toggleAddUser.bind(this)
    this.toggleEditUser = this.toggleEditUser.bind(this)
    this.getAddInputClass = this.getAddInputClass.bind(this)
    this.getEditInputClass = this.getEditInputClass.bind(this)
    this.getEditBtnClass = this.getEditBtnClass.bind(this)
    this.checkAddValidators = this.checkAddValidators.bind(this)
    this.checkEditValidators = this.checkEditValidators.bind(this)
    this.getAddBtnClass = this.getAddBtnClass.bind(this)
    this.closeAllEdits = this.closeAllEdits.bind(this)
  }

  handleAddUserInputChange(event) {
    let userVals = this.state.newUser
    let validatorsVals = this.state.addUserValidators
    switch (event.target.name) {
      case 'name':
        validatorsVals.name = ValidateName(event.target.value)
        this.setState({ addUserValidators: validatorsVals })
        break
      case 'email':
        validatorsVals.email = ValidateEmail(event.target.value)
        this.setState({ addUserValidators: validatorsVals })
        break
      case 'password':
        validatorsVals.password = ValidatePassword(event.target.value)
        this.setState({ addUserValidators: validatorsVals })
        break
    }
    userVals[event.target.name] = event.target.value
    this.setState({ newUser: userVals })
    this.checkAddValidators()
  }

  savedSuccess() {
    setTimeout(() => {
      this.setState({
        success: 2,
      })
    }, 2000)
    return (
      <div>Saved</div>
    )
  }

  resolveButtonLabel() {

    switch (this.state.success) {
      case 0:
        return 'Saving...'
      case 1:
        return this.savedSuccess()
      case 2:
        return 'Add user'
      default:
        break

    }
  }

  handleEditUserInputChange(event) {
    let userVals = this.state.editUser
    let validatorsVals = this.state.editUserValidators
    switch (event.target.name) {
      case 'name':
        validatorsVals.name = ValidateName(event.target.value)
        this.setState({ editUserValidators: validatorsVals })
        break
      case 'email':
        validatorsVals.email = ValidateEmail(event.target.value)
        this.setState({ editUserValidators: validatorsVals })
        break
      case 'password':
        validatorsVals.password = ValidatePassword(event.target.value)
        this.setState({ editUserValidators: validatorsVals })
        break
    }
    userVals[event.target.name] = event.target.value
    this.setState({ editUser: userVals })
    this.checkEditValidators()
  }

  addUser(event) {
    if (this.state.isAddValid) {
      // Save new user to DB
      AddNewUser(this.state.newUser, this)
      this.setState({
        success: 0,
        newUser: {
          name: '',
          email: '',
          password: '',
        }, isAddValid: false, addUserValidators: {
          name: null,
          email: null,
          password: null,
        },
      })
    }
    event.preventDefault()
  }

  editUser(event) {
    if (this.state.isEditValid) {
      let userVals = this.state.editUser
      EditUser(userVals, this, true)
      this.closeAllEdits()
      this.setState({
        editUser: {
          name: '',
          email: '',
          password: '',
        }, isEditValid: false, editUserValidators: {
          name: null,
          email: null,
          password: null,
        },
      })
    }
    event.preventDefault()
  }

  deleteUser(event) {
    let params = event.currentTarget.dataset
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1><i className="fas fa-exclamation-triangle"></i>Delete warning!</h1>
            <p>This action is permanent. Would you like to proceed?</p>
            <div className="line"></div>
            <button className="yes"
                    onClick={() => {
                      DeleteUserById(params.id, this)
                      onClose()
                    }}
            >
              <i className="far fa-trash-alt"></i>Yes
            </button>
            <button className="no" onClick={onClose}>No</button>
          </div>
        )
      },
    })
    event.preventDefault()
  }

  componentWillMount() {
    ValidateToken()
    document.title = 'My users | Personalizard'
    const _identity = Identity()
    if (sessionStorage.getItem('userData')) {
      if (GetRole(_identity.role !== Roles.User)) {
        GetUsersByOrganization(this)
        this.setState({
          identity: _identity, addUserValidators: {
            name: null,
            email: null,
            password: null,
          }, AddNewUser: {
            name: '',
            email: '',
            password: '',
          },
          isAuthenticated: true,
        })
      } else {
        this.setState({ isAuthenticated: false, loading: false })
      }
    } else {
      this.setState({ redirect: true })
    }
  }

  // Make as single component
  static renderSingleUser(user, instance) {
    let username = user.name
    return (
      <div className="users-item" key={user.name} item={user}>
        <h1 className="user-name">
          <i className="fas fa-user-circle"></i>
          {username}
        </h1>
        <div className="user-email-controls">
          <div className="user-email">
            <i className="far fa-envelope"></i>
            {user.email}
          </div>
          {user.id != instance.state.identity.userId &&
          <div className="user-controls">
            <button className="user-controls-item delete-item" data-item={JSON.stringify(user)}
                    onClick={instance.toggleEditUser}>
              <i className="fas fa-pen"></i>
              Edit
            </button>
            <button className="user-controls-item delete-item" data-id={user.id} onClick={instance.deleteUser}>
              <i className="far fa-trash-alt"></i>
              Delete
            </button>
          </div>
          }
        </div>
        <div className="user-stats">
          <span>Videos: {user.videosCount}</span>
          <span>Created: {moment(user.createdDate).format('YYYY/M/D')}</span>
        </div>
        <div className="user-edit">
          {instance.state.editUser != null && instance.state.editUser.id === user.id &&
          <form onSubmit={instance.editUser}>
            <div className={instance.getEditInputClass('name')}>
              <input id="user-edit-name" name="name" type="text" value={instance.state.editUser.name}
                     onChange={instance.handleEditUserInputChange} required/>
              <label htmlFor="user-edit-name">Name</label>
            </div>
            <div className={instance.getEditInputClass('email')}>
              <input id="user-edit-email" name="email" type="email" value={instance.state.editUser.email}
                     onChange={instance.handleEditUserInputChange} required/>
              <label htmlFor="user-edit-email">Email</label>
            </div>
            <div className={instance.getEditInputClass('password')}>
              <input id="user-edit-password" name="password" type="password" value={instance.state.editUser.password}
                     onChange={instance.handleEditUserInputChange}/>
              <label htmlFor="user-edit-password">One-use password</label>
              <i className="far fa-question-circle">
                <div className="quesPopup">At least 6 chars.</div>
              </i>
            </div>
            <button disabled={!instance.state.isEditValid} className={instance.getEditBtnClass()}>Save</button>
          </form>
          }
        </div>
      </div>
    )
  }


  // User List
  static renderUsers(users = [], instance) {
    if (users.length <= 0)
      return (
        <div className="items-block">
          <div className="items-message">No users found</div>
        </div>
      )
    else
      return users.map(user => <div className="items-block"
                                    key={user.id}>{MyUsers.renderSingleUser(user, instance)}</div>)
  }

  toggleAddUser() {
    if ($('.page-top-panel-body').is(':hidden')) $('.page-top-panel-header i').css({
      transform: 'rotateZ(180deg)',
      bottom: '2.5px',
    })
    else $('.page-top-panel-header i').css({ transform: 'rotateZ(0deg)', bottom: '0' })
    $('.page-top-panel-body').toggle()
    this.setState({
      newUser: {
        name: '',
        email: '',
        password: '',
      }, isAddValid: false, addUserValidators: {
        name: null,
        email: null,
        password: null,
      },
    })
  }

  toggleEditUser(event) {
    const active = $(event.currentTarget).hasClass('active-item')
    this.closeAllEdits()
    if (active) $(event.currentTarget).removeClass('active-item')
    else $(event.currentTarget).addClass('active-item')
    if (!active) {
      $(event.currentTarget).parent().parent().parent().children('.user-edit').toggle()
      let object = JSON.parse(event.currentTarget.dataset.item)
      object.password = ''
      this.setState({
        editUser: object,
        isEditValid: false,
        editUserValidators: {
          name: ValidateName(object.name),
          email: ValidateEmail(object.email),
          password: ValidatePassword(object.password),
        },
      })
    }
    event.preventDefault()
  }

  closeAllEdits() {
    $('.user-edit').each(function() {
      $(this).hide()
    })
    $('.active-item').each(function() {
      $(this).removeClass('active-item')
    })
  }

  getAddInputClass(name) {
    if (this.state.addUserValidators[name] == null) return ('form-label-input-block')
    else if (this.state.addUserValidators[name] == false) return ('form-label-input-block nonvalid-field')
    else return ('form-label-input-block valid-field')
  }

  getEditInputClass(name) {
    if (this.state.editUserValidators[name] == null) return ('form-label-input-block')
    else if (this.state.editUserValidators[name] == false) return ('form-label-input-block nonvalid-field')
    else return ('form-label-input-block valid-field')
  }

  checkAddValidators() {
    const validators = this.state.addUserValidators
    if (validators.name == true && validators.email == true && validators.password == true) this.setState({ isAddValid: true })
    else this.setState({ isAddValid: false })
  }

  checkEditValidators() {
    const validators = this.state.editUserValidators
    if (validators.name == true && validators.email == true && (validators.password == true || validators.password == null)) this.setState({ isEditValid: true })
    else this.setState({ isEditValid: false })
  }

  getAddBtnClass() {
    if (this.state.isAddValid) return ('form-submit-button form-submit-button-active')
    else if (this.state.success === 1) return ('form-submit-button-success-fetch')
    else return ('form-submit-button')
  }

  getEditBtnClass() {
    if (this.state.isEditValid) return ('form-submit-button form-submit-button-active')
    else if (this.state.success === 1) return ('form-submit-button-success-fetch')
    else return ('form-submit-button')
  }

  render() {
    if (this.state.redirect) return (<Redirect to={'/login'}/>)

    let loader = this.state.loading ? RenderLoader() : ''
    let userContents = this.state.loading
      ? <div className="items-block">
        <div className="items-message">Loading...</div>
      </div>
      : MyUsers.renderUsers(this.state.users, this)

    return (
      <main className="main-content block-layout">
        {loader}
          {!this.state.isAuthenticated && <NoAuthenticated/>}
          {this.state.isAuthenticated &&
          <section className="section-layout">
              <div className="page-top-panel">
                  <div className="page-top-panel-header" onClick={this.toggleAddUser}>
                      <h1>Add user</h1>
                      <i className="fas fa-chevron-down"></i>
                  </div>
                  <div className="page-top-panel-body">
                      <form onSubmit={this.addUser}>
                          <div className={this.getAddInputClass('name')}>
                              <input id="user-name" type="text" name="name" value={this.state.newUser.name}
                                     onChange={this.handleAddUserInputChange} required/>
                              <label htmlFor="user-name">Name</label>
                          </div>
                          <div className={this.getAddInputClass('email')}>
                              <input id="user-email" type="email" name="email" value={this.state.newUser.email}
                                     onChange={this.handleAddUserInputChange} required/>
                              <label htmlFor="user-email">Email</label>
                          </div>
                          <div className={this.getAddInputClass('password')}>
                              <input id="user-password" type="password" name="password"
                                     value={this.state.newUser.password}
                                     onChange={this.handleAddUserInputChange} required/>
                              <label htmlFor="user-password">One-use password</label>
                              <i className="far fa-question-circle">
                                  <div className="quesPopup">At least 6 chars</div>
                              </i>
                          </div>
                          <button disabled={!this.state.isAddValid}
                                  className={this.getAddBtnClass()}>{this.resolveButtonLabel()}</button>
                      </form>
                  </div>
              </div>
          </section>
          }
          {this.state.isAuthenticated &&
          <section className="section-layout">
              <h1 className="section-header-1">My users</h1>
              {userContents}
          </section>
          }
      </main>
    )
  }
}