import React, {useState, useEffect} from "react"
import "./index.css"
const Switch = ({id, state, updateValue}) => {
  const [switchValue, setSwitchValue] = useState(state)

  const onSwitchChange = (event) => {
    setSwitchValue(!switchValue)
    updateValue(id, !switchValue)
  }

  // TODO: Call server
 // useEffect(() => updateValue(id, switchValue), [switchValue]);

  return(
    <div>
      <label className="switch">
          <input name={id} type="checkbox" onChange={onSwitchChange} checked={switchValue}/>
          <span className="slider round"/>
      </label>
    </div>
  )
}
export default Switch