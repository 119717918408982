import React, {useEffect, useState} from "react"
import * as routes from "./../../../../../routes"
import {Link} from "react-router-dom"
import {MenuWrapper, MenuItem} from "./styled"
import { GetRole, Roles } from '../../../../Services/RoleService'
import { Identity } from '../../../../Services/IdentityService'
import { ValidateToken } from '../../../../Services/TokenValidityService'
import { GetUserOverview } from '../../../../Services/UsersServices/GetUserOverviewService'
import OutsideAlerter from '../../../OutsideClick'
const ProfileMenu = ({closeMenu, dropMenu}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [identityRole, setIdentityRole] = useState("")

  useEffect(() => {
    ValidateToken();
    const fetchData = async () => {
      const identity = await Identity();
      setIdentityRole(identity)
    }
      fetchData()
    setIsLoading(false)
  },[])

  return(
    <MenuWrapper>
      <OutsideAlerter dropMenu={()=> dropMenu()}>
      {!isLoading &&
        <>
      <Link onClick={closeMenu} to={routes.SETTINGS}>
      <MenuItem>
        <button className="top-panel-user-logout">My account<i className="fas fa-cog"></i></button>
      </MenuItem>
      </Link>
          {(GetRole(identityRole.role) !== Roles.SysAdmin) &&
            <div>
          <Link onClick={closeMenu} to={routes.NOTIFICATION_SETTINGS}>
        <MenuItem>
          <button className="top-panel-user-logout">Notifications<i className="fas fa-bell"></i></button>
        </MenuItem>
          </Link>
          </div>
            }
        <Link onClick={closeMenu} to={routes.LOGOUT}>
          <MenuItem>
            <button className="top-panel-user-logout">Sign Out<i className="fas fa-sign-out-alt"></i></button>
          </MenuItem>
        </Link>
        <Link onClick={closeMenu} to={routes.SUPPORT}>
          <MenuItem>
          <button className="top-panel-user-logout">Contact Support<i className="fas fa-life-ring"></i></button>
          </MenuItem>
        </Link>
        </>
      }
      </OutsideAlerter>
    </MenuWrapper>

  )
}
export default ProfileMenu