import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-v3'
import * as routes from './routes'
/** Layouts **/
import NoMenuLayoutRoute from './components/Layouts/NoMenuLayout';
import MenuLayoutRoute from './components/Layouts/MenuLayout';
import MenuExtendedLayoutRoute from './components/Layouts/MenuExtendedLayout';

/** Components **/ 
import { LogIn } from './components/Pages/LogIn';
import { LogOut } from './components/Pages/LogOut';
import { Dashboard } from './components/Pages/Dashboard';
import { MyVideos } from './components/Pages/MyVideos/MyVideos';
import { MyUsers } from './components/Pages/MyUsers';
import { NewVideo } from './components/Pages/NewVideo';
import { Video } from './components/Pages/Video/Video';
import { Settings } from './components/Pages/Settings/Settings';
import { MyTemplates } from './components/Pages/MyTemplates';
import { Organizations } from './components/Pages/Organizations';
import { OneTimePassword } from './components/Pages/OneTimePassword';
import SupportForm from './components/Pages/Support'
import { ReRender } from './components/Pages/ReRender';
import NotificationSettings from "./components/Pages/Settings/NotificationSettings/index"

export default class App extends Component {
  static displayName = App.name;

    componentDidMount() {
        loadReCaptcha("6LeazrkUAAAAAB1ox2YG2BrAUDsLdVGX3xrMhV06");
    }

  render () {
      return (
          <Router>
              <Switch>
                  <Route exact path="/">
                      <Redirect to="/dashboard" />
                  </Route>
                  <NoMenuLayoutRoute path={routes.LOGIN} component={LogIn} />
                  <NoMenuLayoutRoute path={routes.VIDEO} component={Video} />
                  <NoMenuLayoutRoute path="/onetime-password" component={OneTimePassword} />
                  <Route path={routes.LOGOUT} component={LogOut} />
                  <MenuExtendedLayoutRoute path={routes.DASHBOARD} title="Dashboard" component={Dashboard} />
                  <MenuLayoutRoute path={routes.VIDEOS} title="My videos" component={MyVideos} />
                  <MenuLayoutRoute path={routes.USER} title="My users" component={MyUsers} />
                  <MenuLayoutRoute path={routes.TEMPLATE} title="My templates" component={MyTemplates} />
                  <MenuLayoutRoute path={routes.ORGANIZATION} title="Organizations" component={Organizations} />
                  <MenuLayoutRoute path={routes.NEWVIDEO} title="New video" component={NewVideo} />
                  <MenuLayoutRoute path={routes.SETTINGS} title="Settings" component={Settings} />
                  <MenuLayoutRoute path={routes.SUPPORT} title="Support form" component={SupportForm} />
                  <MenuLayoutRoute path={routes.RERENDER} title="Re-render video" component={ReRender} />
                  <MenuLayoutRoute path={routes.NOTIFICATION_SETTINGS} title="Notification Settings" component={NotificationSettings} />

              </Switch>
          </Router> 
    );
  }
}
