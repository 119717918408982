import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { ValidateToken } from '../Services/TokenValidityService'
import '../Resources/Stylesheets/MyTemplates/mytemplates.css'
import $ from 'jquery'
import moment from 'moment'
import { AddNewTemplate } from '../Services/TemplatesServices/AddTemplateService'
import { GetTemplatesByOrganization } from '../Services/TemplatesServices/TemplatesByOrganizationService'
import { ValidateTemplate } from '../Validators/TemplateValidatorService'
import { DeleteTemplateById } from '../Services/TemplatesServices/DeleteTemplateService'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { GetTemplateOverview } from '../Services/TemplatesServices/GetTemplateOverviewService'
import { RenderLoader } from '../Components/General/Loader'
import * as AComplete from 'react-autocomplete'
import { GetUsersByOrganization } from '../Services/UsersServices/UsersByOrganizationService'
import { editTemplate } from '../Services/TemplatesServices/edit-template'
import Switch from "./../Components/FormComponents/Switch"
import { Identity } from '../Services/IdentityService'
import { ValidateUrl } from '../Validators/UrlValidatorService'
import { GetRole, Roles } from '../Services/RoleService'
import NoAuthenticated from "./NoAuthenticated"
import videoPreview from "./../Resources/Images/video-preview.png"

export class MyTemplates extends Component {
  static displayName = MyTemplates.name

  constructor(props) {
    super(props)
    this.state = {
      identity: null,
      isAuthenticated: false,
      redirect: false,
      loading: true,
      templates: [],
      isValid: false,
      isEditValid: false,
      userSelected: false,
      user: null,
      userLoaded: false,
      users: [],
      success: 2,
      editSuccess: 2,
      usersEdited: [],
      newTemplate: {
        name: '',
          webUrl: '',
          ctaUrl: '',
        ctaVisible: '',
        key: '',
        removedUsers: [],

      },
      addTemplateValidators: {
        key: null,
          webUrl: null,
        ctaUrl: null,
        name: null,

      },
        editTemplateValidators: {
          ctaUrl: null,
        webUrl: null,
        removedUsers: null,
      },
      isAddValid: false,
      editTemplate: {
        isEdited: false,
        templateId: null,
      },
      templateEditing: {
        template: null,
        key: null,
        name: null,
          webUrl: null,
          ctaUrl: null,
        ctaVisible: null,
        removedUsers: [],
      },
    }
    this.handleAddTemplateInputChange = this.handleAddTemplateInputChange.bind(this)
    this.addTemplate = this.addTemplate.bind(this)
    this.deleteTemplate = this.deleteTemplate.bind(this)
    this.toggleAddTemplate = this.toggleAddTemplate.bind(this)
    this.getAddBtnClass = this.getAddBtnClass.bind(this)
    this.getEditBtnClass = this.getEditBtnClass.bind(this)
    this.checkAddValidators = this.checkAddValidators.bind(this)
    this.getAddInputClass = this.getAddInputClass.bind(this)
    this.handleEditTemplate = this.handleEditTemplate.bind(this)
    this.getEditBtnClass = this.getEditBtnClass.bind(this)
    this.addRestrictedUser = this.addRestrictedUser.bind(this)
      this.closeAllEdits = this.closeAllEdits.bind(this)
      this.handleCtaValueChanged = this.handleCtaValueChanged.bind(this)
      this.handleCtaEditValueChanged = this.handleCtaEditValueChanged.bind(this)
  }

  componentWillMount() {
    ValidateToken()
    document.title = 'My templates | Personalizard'
    if (sessionStorage.getItem('userData')) {
      const _identity = Identity()
      this.setState({ identity: _identity })
      if (GetRole(_identity.role !== Roles.User)) {
        GetTemplatesByOrganization(this)
        GetUsersByOrganization(this)
        this.setState({
          isAuthenticated: true
        })
      } else {
        this.setState({isAuthenticated: false, loading: false})
      }
    } else {
      this.setState({ redirect: true })
    }
  }

  handleAddTemplateInputChange(event) {
    let templateVals = this.state.newTemplate
    let addTemplateValidators = this.state.addTemplateValidators
    switch (event.target.name) {
      case 'key':
        ValidateTemplate(event.target.value, this.state.templates, this, 'addTemplateValidators', true)
        break
      case 'webUrl':
        addTemplateValidators.webUrl = ValidateUrl(event.target.value, true)
        this.setState({ addTemplateValidators: addTemplateValidators })
            break
        case 'ctaUrl':
            addTemplateValidators.ctaUrl = ValidateUrl(event.target.value, true)
            this.setState({ addTemplateValidators: addTemplateValidators })
            break
    }
    templateVals[event.target.name] = event.target.value
    this.setState({ newTemplate: templateVals })
    this.checkAddValidators()
  }

  closeAllEdits() {
    $('.tempEdit').each(function() {
      $(this).hide()
    })
    this.setState({
      editTemplate: {
        isEdited: false,
        templateId: null,
      },
    })
    $('.active-item').each(function() {
      $(this).removeClass('active-item')
    })
  }

  editedSuccess() {
    setTimeout(() => {
      this.setState({
        editSuccess: 2,
      })
    }, 2000)
    return (
      <div>Saved</div>
    )
  }

  savedSuccess() {
    setTimeout(() => {
      this.setState({
        success: 2,
      })
    }, 2000)
    return (
      <div>Saved</div>
    )
  }

  resolveButtonLabel() {
    switch (this.state.success) {
      case 0:
        return 'Saving...'
      case 1:
        return this.savedSuccess()
      case 2:
        return 'Add Template'
      default:
        break

    }
  }


  resolveEditButtonLabel() {

    switch (this.state.editSuccess) {
      case 0:
        return 'Saving...'
      case 1:
        return this.editedSuccess()
      case 2:
        return 'Edit Template'
      default:
        break

    }
  }

  addTemplate(event) {
    if (this.state.isAddValid) {
      let template = GetTemplateOverview(this.state.newTemplate.key)
      delete template.addedDate
      delete template.thumbnail
      template.webUrl = this.state.newTemplate.webUrl
        template.name = this.state.newTemplate.name
        template.ctaUrl = this.state.newTemplate.ctaUrl
        template.ctaVisible = this.state.newTemplate.ctaVisible === "true"
      template.excludedUsers = this.state.newTemplate.removedUsers ? this.state.newTemplate.removedUsers.map(user => user.id) : []

      this.setState({
        success: 0,
      })
      AddNewTemplate(template, this)
      this.setState({
        usersEdited: this.state.users,
        newTemplate: {
          key: '',
          removedUsers: [],
          name: '',
          webUrl: '',
        },
        templateEditing: {
          removedUsers: [],
        },
        addTemplateValidators: {
          key: null,
          webUrl: null,
          name: null,
        },
        isAddValid: false,
      })
    }
    event.preventDefault()
  }

  getAddInputClass(name) {
    if (this.state.addTemplateValidators[name] == null) return ('form-label-input-block')
    else if (this.state.addTemplateValidators[name] == false) return ('form-label-input-block nonvalid-field')
    else return ('form-label-input-block valid-field')
  }

  getAddBtnClass() {
    if (this.state.isAddValid) return ('form-submit-button form-submit-button-active')
    if (this.state.success === 1) return ('form-submit-button-success-fetch')
    else return ('form-submit-button')
  }

  getEditBtnClass() {
    if (this.state.isEditValid) return ('form-submit-button form-submit-button-active')
    if (this.state.editSuccess === parseInt(1)) return ('form-submit-button-success-fetch')
    else return ('form-submit-button')
  }

  checkAddValidators() {
    const validators = this.state.addTemplateValidators
    if (validators.key == true && validators.webUrl === true || validators.webUrl === null) {
      this.setState({ isAddValid: true })
    } else this.setState({ isAddValid: false })
  }

  checkEditValidators() {
    const validators = this.state.editTemplateValidators
    if (validators.webUrl === true || validators.webUrl === null && this.state.templateEditing.removedUsers.length >= 0) {
      this.setState({ isEditValid: true })
    } else this.setState({ isEditValid: false })
  }

  toggleEdit(event, template, instance) {
    const active = $(event.currentTarget).hasClass('active-item')
    this.closeAllEdits()
    if (active) $(event.currentTarget).removeClass('active-item')
    else $(event.currentTarget).addClass('active-item')
      if (!active) {
      GetUsersByOrganization(this)
      instance.setState(prevState => ({
        editTemplate: {
          isEdited: !prevState.isEdited,
          templateId: template.id,
        },
        usersEdited: prevState.users.filter(user => template.excludedUsers.every(exc => exc.id !== user.id)).filter(user => user.id != this.state.identity.userId),
        templateEditing: {
          template: template,
          name: template.name,
            webUrl: template.webUrl,
            ctaUrl: template.ctaUrl,
            ctaVisible: template.ctaVisible,
          key: template.key,
          removedUsers: template.excludedUsers,
        },
        user: null,
      }))
    }

  }


  handleEditTemplate = (event) => {

    let editTemplateValidators = this.state.editTemplateValidators
    switch (event.target.name) {
      case 'webUrl':
        editTemplateValidators.webUrl = ValidateUrl(event.target.value, true)
        this.setState({ addTemplateValidators: editTemplateValidators })
            break
        case 'ctaUrl':
            editTemplateValidators.ctaUrl = ValidateUrl(event.target.value, true)
            this.setState({ addTemplateValidators: editTemplateValidators })
            break
    }

    (this.state.templateEditing.name !== null || this.state.editTemplate.name !== '') ? this.setState({ isValid: true }) : this.setState({ isValid: false })
    this.setState({
      templateEditing: {
        ...this.state.templateEditing,
        template: {
          ...this.state.templateEditing.template,
          [event.target.id]: event.target.value,
        },
      },
    })
    this.checkEditValidators()
  }

  addRestrictedUser = (event) => {
    event.preventDefault()
    if (this.state.user !== null) {
      this.setState(prevState => ({
        newTemplate: {
          ...prevState.newTemplate,
          removedUsers: [...prevState.newTemplate.removedUsers, prevState.user],
        },
        search: '',
        user: null,
        usersEdited: prevState.usersEdited.filter(function(person) {
          return person.id !== prevState.user.id
        }),
        isValid: true,
      }))
    }
  }

  addRestrictedUserEdit = (event) => {
    event.preventDefault()
    if (this.state.user !== null) {
      this.setState(prevState => ({
        templateEditing: {
          ...prevState.templateEditing,
          removedUsers: [...prevState.templateEditing.removedUsers, prevState.user],
        },
        search: '',
        user: null,
        usersEdited: prevState.usersEdited.filter(function(person) {
          return person.id !== prevState.user.id
        }),
        isEditValid: true,
      }))
    }
  }

  handleEditSubmit = (event) => {
    this.setState({
      editSuccess: 0,
      isEditValid: false,
    })
    this.setState({
      addTemplateValidators: {
        webUrl: null,
      },
    })
    const { templateEditing } = this.state
    templateEditing.template.excludedUsers = templateEditing.removedUsers

    const newTemplates = this.state.templates.map(u => {
      if (u.id === templateEditing.template.id) {
        return {
          ...templateEditing.template,
        }
      }
      return u
    })

    this.setState({
      templates: newTemplates,
    })

    editTemplate({
      data: {
        ...templateEditing.template,
        excludedUsers: this.state.templateEditing.removedUsers ? templateEditing.removedUsers.map(user => user.id) : [],
      },
    }).then(() => this.setState({
      editSuccess: 1,
    }))
    event.preventDefault()
  }

  removeUser = (userId) => {
    this.setState({
      usersEdited: [...this.state.usersEdited, ...this.state.users.filter(user => user.id === userId)],
      newTemplate: {
        ...this.state.newTemplate,
        removedUsers: [...this.state.newTemplate.removedUsers.filter(user => user.id !== userId)],
      },
    })
  }

  removeUserEdit = (userId) => {

    this.setState({
      usersEdited: [...this.state.usersEdited, ...this.state.users.filter(user => user.id === userId)],
      templateEditing: {
        ...this.state.templateEditing,
        removedUsers: [...this.state.templateEditing.removedUsers.filter(user => user.id !== userId)],
      },
    })
    this.checkEditValidators()
  }

  onAddChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    })
  }

  deleteTemplate(event) {
    let params = event.currentTarget.dataset
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1><i className="fas fa-exclamation-triangle"></i>Delete warning!</h1>
            <p>This action is permanent. Would you like to proceed?</p>
            <div className="line"></div>
            <button className="yes"
                    onClick={() => {
                      DeleteTemplateById(params.id, this)
                      onClose()
                    }}
            >
              <i className="far fa-trash-alt"></i>Yes
            </button>
            <button className="no" onClick={onClose}>No</button>
          </div>
        )
      },
    })
    event.preventDefault()
  }

  static renderSingleTemplate(template, instance) {
    return (
      <div style={{ flexWrap: 'wrap', padding: 0, borderRadius: '20px', border: '1px solid #eeeeee', margin: '15px 0' }}
           key={template.id} item={JSON.stringify(template)}>
        <div className="template-item" style={{ border: '0px', margin: '0' }}>
                <img className="template-item-image" src={template.thumbnail === "about:blank" ? videoPreview : template.thumbnail }/>
          <div className="template-item-info">
            <h1 className="template-item-info-header">
              <i className="fas fa-mask"></i>
              {template.name}
            </h1>
            <div className="template-item-info-controls">
              <div className="template-item-control template-info-key">
                <i className="fas fa-key"></i>
                {template.key}
              </div>
              <button style={{ display: 'flex', alignItems: 'center' }}
                      className="template-item-delete-link delete-item" data-id={template.id}
                      onClick={(e) => instance.toggleEdit(e, template, instance)}>
                <i style={{ marginRight: '8px' }} className="fas fa-pen"/>
                <div className="template-item-control">
                  Edit
                </div>
              </button>
              <button style={{ display: 'flex', alignItems: 'center' }}
                      className="template-item-delete-link delete-item" data-id={template.id}
                      onClick={instance.deleteTemplate}>
                <i style={{ marginRight: '8px' }} className="far fa-trash-alt"/>
                <div className="template-item-control">
                  Delete
                </div>
              </button>
            </div>
            <div className="template-item-info-details">
              <span style={{ paddingRight: '16px' }}>Added: {moment(template.addedDate).format('YYYY/M/D')}</span>
              {template.webUrl &&
              <span><a href={template.webUrl} target="_blank">View Detail</a></span>
              }
            </div>
          </div>
        </div>
        {(instance.state.editTemplate.isEdited && instance.state.editTemplate.templateId === template.id) &&
        <div className="tempEdit">
          <div className="form-label-input-block autocomplete" style={{ marginBottom: 0 }}>
            <form>
              <div className="colored-layout" style={{ padding: '8px 30px', margin: '0' }}>
                <div style={{ marginTop: '16px' }} key="name">
                  <label htmlFor="template">Template name</label>
                  <input id="name" type="text" name="name"
                         value={instance.state.templateEditing.name === null ? template.name : instance.state.templateEditing.template.name}
                         onChange={instance.handleEditTemplate} required={true}/>
                </div>

                <div style={{ marginTop: '16px' }} className={instance.getAddInputClass('webUrl')}>
                  <input id="webUrl" type="text" name="webUrl"
                         value={instance.state.templateEditing.webUrl === null ? template.webUrl : instance.state.templateEditing.template.webUrl}
                         onChange={instance.handleEditTemplate}/>
                  <label htmlFor="webUrl">Showcase Url</label>

                            </div>
                            {instance.state.templateEditing.template.ctaAvailable && <div className={instance.getAddInputClass('ctaUrl')}>
                                <input id="ctaUrl" type="text" name="ctaUrl" value={instance.state.templateEditing.ctaUrl === null ? template.ctaUrl : instance.state.templateEditing.template.ctaUrl}
                                    onChange={instance.handleEditTemplate} />
                                <label htmlFor="ctaUrl">CTA URL</label>
                                <i className="far fa-question-circle">
                                    <div className="quesPopup">URL for Click-to-action</div>
                                </i>
                            </div>}

                            {instance.state.templateEditing.template.ctaAvailable && <div className={instance.getAddInputClass('ctaUrl')}>
                                <Switch updateValue={instance.handleCtaEditValueChanged} state={instance.state.templateEditing.template.ctaVisible} id="ctaVisible" />
                                <label>CTA Visible</label>
                            </div>}
                <div style={{ marginTop: '16px' }} key="restrictUser">
                  <label htmlFor="restrictUser">Restrict user</label>
                  <div className="autocomplete" style={{ display: 'flex', alignItems: 'center' }}>
                    <AComplete
                      getItemValue={(item) => item.name}
                      items={instance.state.usersEdited}
                      renderItem={(item, isHighlighted) =>
                        <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                          <div style={{ display: 'flex' }}>
                            <div style={{ paddingRight: '8px' }}>{item.name}</div>
                            <div>{item.email}</div>
                          </div>
                        </div>
                      }
                      value={instance.state.search}
                      onChange={(event, search) => instance.setState({
                        search,
                        template: null,
                        userLoaded: false,
                        userSelected: false,
                      })}
                      onSelect={(search, item) => {
                        instance.setState({ search, userSelected: true })
                        instance.setState({ user: item })
                      }}
                    />

                    <button style={{ color: instance.state.userSelected ? '#33B58F' : null, paddingLeft: '32px' }}
                            className={'add-btn'} onClick={(e) => instance.addRestrictedUserEdit(e)}><i
                      style={{ color: instance.state.userSelected ? '#33B58F' : null }} className="fas fa-plus"/>Add
                    </button>
                  </div>
                </div>
                {instance.state.templateEditing.removedUsers &&
                <div style={{ marginTop: '16px' }} key="restrictedUser">
                  <label htmlFor="restrictedUser">Restricted user</label>
                  {instance.state.templateEditing.removedUsers &&
                  instance.state.templateEditing.removedUsers.map(user =>
                    <div key={user.id}>
                      <button type="button" className="remove-template" data-id={user.id}
                              onClick={() => instance.removeUserEdit(user.id)}><i className="far fa-times-circle"></i>
                      </button>
                      {user.name}</div>)
                  }
                </div>
                }
                <div className="save-button">
                  <button disabled={!instance.state.isEditValid} className={instance.getEditBtnClass()}
                          onClick={(e) => instance.handleEditSubmit(e, instance)}
                          style={{ marginTop: '32px' }}>{instance.resolveEditButtonLabel()}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        }
      </div>
    )
  }


    handleCtaValueChanged(id, val) {
        let newTemplate = this.state.newTemplate;
        newTemplate[id] = val;
        this.setState({ newTemplate: newTemplate });
    }

    handleCtaEditValueChanged(id, val) {
        let template = this.state.templateEditing;
        template[id] = val;
        template.template[id] = val;
        this.setState({ templateEditing: template });
        this.checkEditValidators()
    }

  static renderTemplates(templates = [], instance) {
    if (templates.length <= 0)
      return (
        <div className="items-block">
          <div className="items-message">No templates found</div>
        </div>
      )
    else
      return (
        <div className="items-block">
          {templates.map(template => MyTemplates.renderSingleTemplate(template, instance))}
        </div>
      )
  }

  toggleAddTemplate() {
    this.setState(prevState => ({
      success: 2,
      usersEdited: prevState.users.filter(user => user.id != this.state.identity.userId),
    }))
    if ($('.page-top-panel-body').is(':hidden')) $('.page-top-panel-header i').css({
      transform: 'rotateZ(180deg)',
      bottom: '2.5px',
    })
    else $('.page-top-panel-header i').css({ transform: 'rotateZ(0deg)', bottom: '0' })
    $('.page-top-panel-body').toggle()
  }

  render() {
    if (this.state.redirect) return (<Redirect to={'/login'}/>)
    let loader = this.state.loading ? RenderLoader() : ''
    let templateContents = this.state.loading
      ? <div className="items-block">
        <div className="items-message">Loading...</div>
      </div>
      : MyTemplates.renderTemplates(this.state.templates, this)

    return (
      <main className="main-content block-layout">
        {loader}

        {!this.state.isAuthenticated && <NoAuthenticated/>}
        {this.state.isAuthenticated &&
        <section className="section-layout">
          <div className="page-top-panel">
            <div className="page-top-panel-header" onClick={this.toggleAddTemplate}>
              <h1>Add template</h1>
              <i className="fas fa-chevron-down"></i>
            </div>
            <div className="page-top-panel-body">
              <form onSubmit={this.addTemplate}>

                <div className={this.getAddInputClass('key')}>
                  <input id="template-key" type="text" name="key" value={this.state.newTemplate.key}
                         onChange={this.handleAddTemplateInputChange} required/>
                  <label htmlFor="template-key">Template key</label>
                </div>

                <div
                  className={this.state.newTemplate.name.length > 1 ? 'form-label-input-block valid-field' : 'form-label-input-block'}>
                  <input id="name" type="text" name="name"
                         value={this.state.newTemplate.name}
                         onChange={this.handleAddTemplateInputChange}/>
                  <label htmlFor="name">Template name</label>
                </div>

                <div className={this.getAddInputClass('webUrl')}>
                  <input id="webUrl" type="text" name="webUrl"
                         value={this.state.newTemplate.webUrl}
                         onChange={this.handleAddTemplateInputChange}/>
                  <label htmlFor="webUrl">Showcase URL</label>
                            </div>

                            {this.state.newTemplate.ctaAvailable && <div className={this.getAddInputClass('ctaUrl')}>
                                <input id="org-cta" type="text" name="ctaUrl" value={this.state.newTemplate.ctaUrl}
                                    onChange={this.handleAddTemplateInputChange} />
                                <label htmlFor="org-cta">CTA URL</label>
                                <i className="far fa-question-circle">
                                    <div className="quesPopup">URL for Click-to-action</div>
                                </i>
                            </div>}

                            {this.state.newTemplate.ctaAvailable && <div className={this.getAddInputClass('ctaUrl')}>
                                <Switch updateValue={this.handleCtaValueChanged} state={this.state.newTemplate.ctaVisible} id="ctaVisible" />
                                <label>CTA Visible</label>
                            </div>}

                <div className={'form-label-input-block'}>
                  <div style={{ marginTop: '16px' }} key="restrictUser">
                    <label htmlFor="restrictUser">Restrict user</label>
                    <div className="autocomplete" style={{ display: 'flex', alignItems: 'center' }}>

                      <AComplete
                        getItemValue={(item) => item.name}
                        items={this.state.usersEdited}
                        renderItem={(item, isHighlighted) =>
                          <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingRight: '8px' }}>{item.name}</div>
                              <div>{item.email}</div>
                            </div>
                          </div>
                        }
                        value={this.state.search}
                        onChange={(event, search) => this.setState({
                          search,
                          template: null,
                          userLoaded: false,
                          userSelected: false,
                        })}
                        onSelect={(search, item) => {
                          this.setState({ search, userSelected: true })
                          this.setState({ user: item })
                        }}
                      />

                      <button style={{ color: this.state.userSelected ? '#33B58F' : null, paddingLeft: '32px' }}
                              className={'add-btn'} onClick={(e) => this.addRestrictedUser(e)}><i
                        style={{ color: this.state.userSelected ? '#33B58F' : null }} className="fas fa-plus"/>Add
                      </button>
                    </div>
                  </div>
                </div>
                {this.state.newTemplate.removedUsers &&
                <div className={'form-label-input-block'}>
                  <div style={{ marginTop: '16px' }} key="restrictedUser">
                    <label htmlFor="restrictedUser">Restricted user</label>
                    {this.state.newTemplate.removedUsers.map(user =>
                      <div key={user.id}>
                        <button type="button" className="remove-template" data-id={user.id}
                                onClick={() => this.removeUser(user.id)}><i className="far fa-times-circle"></i>
                        </button>
                        {user.name}</div>)
                    }
                  </div>
                </div>
                }
                <button disabled={!this.state.isAddValid}
                        className={this.getAddBtnClass()}>{this.resolveButtonLabel()}</button>
              </form>
            </div>
          </div>
        </section>
        }
        {this.state.isAuthenticated &&
        <section className="section-layout">
          <h1 className="section-header-1">My templates</h1>
          {templateContents}
        </section>
        }
      </main>
    )
  }
}