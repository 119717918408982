import styled from "styled-components"

export const SharePanel = styled.div`
   font-size: 13px;
   margin-top: 5px;
   border-top: 1px solid #eeeeee;
`
export const Icon = styled.i`
   padding: 4px 0px;
   margin-top: 5px;
   color: #33B58F;
   cursor: pointer;
`

export const IconWithLabel = styled.div`
    display: flex;
    align-items: center;
    color: #33B58F;
`

export const Label = styled.div`
  padding-right: 16px;
  margin-top: 5px;
  cursor: pointer;
  display: inline-block;
 `