import styled from "styled-components"



export const MenuItem = styled.div`
    padding: 8px 34px;
    border-radius: 8px;
    &:hover {
       background-color: #fafafa;
     }
`
export const MenuWrapper = styled.div`
  background-color: white;
  text-align: center;
  position: absolute;
  top: 40px;
  right: -25px;
  width: 200px; 
  border-radius: 8px;
  z-index: 3;
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,1);
  box-shadow: 0px 0px 1px 0px rgba(0,0,0,1);

   @media (max-width: 500px) {
   width: 90%;
   position: fixed;
   top: 66px;
   right: unset;
  }
   
`