import React, { Component } from 'react'
import '../../Resources/Stylesheets/Video/video.css'
import { GetVideoById } from '../../Services/VideoServices/FullVideoByIdService'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import '../../Resources/Stylesheets/General/popup.css'
import { RenderLoader } from '../../Components/General/Loader'
import { Icon, IconWrapper, Column, ColumnRight, WrapContent } from './styled'
import { ShareByFacebook, ShareByTwitter, ShareByLinkedIn } from "./../MyVideos/Share/ShareByApp/index"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { SharePanel, SharePanelVideoDetail } from './styled'
import { ShareByEmail } from '../../Services/VideoServices/ShareByEmailService'
import { ReCaptcha } from 'react-recaptcha-v3'
import MetaTags from 'react-meta-tags'
import { ValidateToken } from '../../Services/TokenValidityService'

export class Video extends Component {
    static displayName = Video.name

    constructor(props) {
        super(props)
        const { match: { params } } = this.props
        this.state = {
            videoId: params.videoId,
            video: null,
            loading: true,
            recaptcha: ""
        }
        this.copiedClipboard = this.copiedClipboard.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        const script = document.createElement("script");

        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;

        document.body.appendChild(script);
    }

    async componentWillMount() {
        await ValidateToken();
        document.title = 'Video | Personalizard'
        if (this.state.videoId) {
            GetVideoById(this.state.videoId, this)
        }
    }
    copiedClipboard() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1><i class="fas fa-check-double"></i>Video sharing</h1>
                        <p>Link copied to clipboard.</p>
                        <div className="line"></div>
                        <button className="yes" onClick={onClose}>Ok</button>
                    </div>
                )
            },
        })
    }

    verifyCallback = (recaptchaToken) => {
        this.setState({ recaptcha: recaptchaToken });
    }

    static sendEmail(video, id, instance) {
        video.id = id
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1><i className="fas fa-envelope" />Share video via email</h1>
                        <p>Please enter email address</p>
                        <div className="line" />
                        <div className="form-label-input-block autocomplete" style={{ paddingBottom: "16px" }}>
                            <form>
                                <div style={{ textAlign: "left" }}>
                                    Email
                </div>
                                <input type="text" id="emailInput" name="emailInput" />
                                <ReCaptcha
                                    sitekey="6LeazrkUAAAAAB1ox2YG2BrAUDsLdVGX3xrMhV06"
                                    action='share'
                                    verifyCallback={instance.verifyCallback}
                                />
                            </form>
                        </div>

                        <button className="yes"
                            onClick={() => {
                                ShareByEmail(video, document.getElementById("emailInput").value, instance.state.recaptcha)
                                instance.setState({ recaptcha: "" });
                                onClose()
                            }}
                        >

                            <i className="far fa-paper-plane" />Send
            </button>
                        <button className="no" onClick={onClose}>No</button>
                    </div>
                )
            },
        })
    }

    render() {
        let loader = this.state.loading ? RenderLoader() : ''
        let videoContent = this.state.loading ?

            <div style={{ textAlign: 'center' }}>Loading...</div> : this.state.video == null ?
                <div style={{ textAlign: 'center' }}>Video not found</div> :
                <div className="video-wrapper">
                    <MetaTags>
                        <meta property="og:site_name" content="Personalizard" />
                        <meta property="og:url" content={window.location.origin + /video/ + this.state.video.id} />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content={this.state.video.videoName + " | Personalizard"} />
                        <meta property="og:description"
                            content="Truly personalized video experience" />
                        <meta property="og:image" content={this.state.video.screenshotUrl} />
                    </MetaTags>
                    <div className="">
                        <div className="video-block">
                            <div className="iframe-wrapper">
                                <iframe
                                    scrolling="no"
                                    className="video-frame"
                                    id="motionlab-player"
                                    src={this.state.video.url}
                                    frameBorder="0" allow="autoplay; encrypted-media"
                                    allowFullScreen scrolling="no"
                                >
                                </iframe>
                            </div>
                            <WrapContent className="video-buttons">
                                <Column>
                                    <h1 className="video-name">{this.state.video.videoName}</h1>
                                    <h2
                                        className="video-organization-info">{this.state.video.creatorName} | {this.state.video.organizationName}</h2>
                                </Column>
                                <ColumnRight>
                                    <h1 className="video-name">Share</h1>
                                    <SharePanelVideoDetail style={{}}>
                                        <IconWrapper>
                                            <CopyToClipboard style={{ cursor: "pointer" }}
                                                text={document.location.origin + '/video/' + this.state.videoId}>
                                                <Icon onClick={this.copiedClipboard} className="fas fa-link" />
                                            </CopyToClipboard>
                                        </IconWrapper>

                                        <a onClick={() => Video.sendEmail(this.state.video, this.state.videoId, this)}>
                                            <IconWrapper><Icon className="fas fa-envelope" /></IconWrapper>
                                        </a>

                                        <ShareByTwitter id={this.state.videoId} picture={this.state.video.screenshotUrl} icon={<IconWrapper>
                                            <Icon className="fab fa-twitter" />
                                        </IconWrapper>} />

                                        <ShareByFacebook title={this.state.video.videoName} id={this.state.videoId} picture={this.state.video.screenshotUrl} icon={<IconWrapper>
                                            <Icon className="fab fa-facebook-f" />
                                        </IconWrapper>} />

                                        <ShareByLinkedIn title={this.state.video.videoName} id={this.state.videoId} picture={this.state.video.screenshotUrl} icon={<IconWrapper>
                                            <Icon className="fab fa-linkedin-in" />
                                        </IconWrapper>} />
                                    </SharePanelVideoDetail>
                                </ColumnRight>
                            </WrapContent>
                        </div>
                    </div>
                </div>
        return (
            <main id="main">
                {loader}
                <div className="video-main-logo-block">
                    <img style={{ maxWidth: "300px", maxHeight: "80px", objectFit: "contain", objectPosition: "center" }} className="video-main-logo" src={(!this.state.loading && this.state.video !== null && this.state.video.image != "" && this.state.video.image != null) ? this.state.video.image : './Resources/Images/General/Personalizard.png'} />
                </div>
                {videoContent}

            </main>
        )
    }
}