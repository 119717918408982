import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';

export function GetTemplateOverview(key) {
    let obj = null;
    $.ajax('/api/Templates/overview?key=' + key, {
        type: 'GET',
        async: false,
        contentType: 'application/json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        success: function (output, status, xhr) {
            if (status == "success") {
                obj = output;
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
        }
    });    
    return obj;
}