import styled from 'styled-components'

export const ProfileIcon = styled.i`
  font-size: 24px;
  color: #33B58F;
  padding-right: 8px;
  padding-left: 32px;

`

export const Name = styled.span`
    cursor: default;
    font-size: 15px;
    display: inline-block;
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    overflow: hidden !important;
    @media (max-width: 868px) {
    display: none;
    }
`
export const CompanyWrap = styled.span`
  font-size: 15px;
  color: lightgray;
`

export const Company = styled.span`
  font-size: 15px;
  color: lightgray;
  margin-left: 2px; 
  max-width: 80px;
  white-space: nowrap;
  display: inline-block !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  
  
  @media (max-width: 868px) {
    display: none;
}
`

export const NotificationButton = styled.span`
  color: ${props => props.isActive ? 'gray' : '#33B58F'};
`

export const Icon = styled.i`
   padding-left: 8px;
   color: grey;
   cursor: pointer;
   @media (max-width: 868px) {
    display: none;
}
`

export const WrapCompanyName = styled.span`
  display: inline-flex;
  cursor: pointer;
   @media (max-width: 868px) {
    display: none;
}
`
