import React, { useEffect, useState } from 'react'
import { Icon, Name, NotificationButton, ProfileIcon, Company, CompanyWrap, WrapCompanyName } from './styled'
import ProfileMenu from './ProfileMenu'
import NotificationCenter from '../NotificationCenter'
import { getNotifications } from '../../../Services/Notification/get-notifications'
import { HubConnectionBuilder } from '@aspnet/signalr'
import { getUserById } from '../../../Services/UsersServices/get-user-by-id'
import { markAsSeen } from '../../../Services/Notification/mark-as-seen'

const Profile = ({ identity }) => {
  const [isDropProfileMenu, setIsDropProfileMenu] = useState(false)
  const [isDropNotificationMenu, setIsDropNotificationMenu] = useState(false)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [numberOfNotification, setNumberOfNotification] = useState(data.map(item => item.seen === false))
  const [hotData, setHotData] = useState(false)
  const [userName, setUserName] = useState('')
  useEffect(() => {
    setHotData(false)

    async function fetchData() {
      const _data = await getNotifications()
      const name = await getUserById(identity.userId)

      setUserName(name.name)
      setData(_data)
      setNumberOfNotification(_data.filter(item => item.seen === false))
      setIsLoading(false)
    }

    fetchData()
    let connection = new HubConnectionBuilder().withUrl('/hubs/notifications', {
      accessTokenFactory: function() {
        return sessionStorage.getItem('userData')
      },
    }).build()
    connection.on('notifications', _data => {
      setHotData(true)
      setData(prev => {
        return [...prev, _data]
      })
      setIsLoading(false)
    })
    connection.start()
    if (data.length > 0) {
      setNumberOfNotification(data.filter(item => item.seen === false))
    }
    return () => {
      connection.stop()
    }
  }, [hotData])
  const closeMenu = () => {
    setIsDropProfileMenu(false)
  }

  const onAllReadMessage = () => {
    setData([])
  }

  const onAllSeen = async () => {
    let ids = []
    data.map(item => ids.push(item.id))
    markAsSeen({
      id: ids,
    })
    const updatedData = data.map(item => {
        return {
          ...item,
          seen: true,
        }
      },
    )
    setData(updatedData)
    setNumberOfNotification([])
  }

  const onReadMessage = (message) => {
    setData(data.filter(prevMessage => prevMessage.id !== message))
  }

  const handleDrop = (name) => {
    switch (name) {
      case 'dropProfile': {
        setIsDropProfileMenu(!isDropProfileMenu)
        setIsDropNotificationMenu(false)
        break
      }
      case 'dropNotification': {
        setIsDropNotificationMenu(!isDropNotificationMenu)
        setIsDropProfileMenu(false)
        onAllSeen()
        break
      }
      default: {
        break
      }
    }
  }


  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: 'relative' }}>
      {(!isLoading && isDropNotificationMenu) &&
      <div style={{ cursor: 'pointer' }}>
      <span style={{ fontSize: '75%' }} className="fa-stack">
        <NotificationButton>
          <i className="far fa-bell fa-stack-2x"/>
        </NotificationButton>
        <span style={{ color: '#33B58F', fontSize: '65%' }} color="red" className="fa-stack-1x">
          {numberOfNotification.length > 9 ? '9+' : numberOfNotification.length}
    </span>
    </span>
      </div>
      }

      {(!isLoading && !isDropNotificationMenu) &&
      <div style={{ cursor: 'pointer' }} onClick={() => setIsDropNotificationMenu(true)}>
            <span style={{ fontSize: '75%' }} className="fa-stack">
        <NotificationButton isActive={numberOfNotification.length === 0}>
          <i className="far fa-bell fa-stack-2x"/>
        </NotificationButton>
        <span style={{ color: numberOfNotification.length === 0 ? '#515254' : '#33B58F', fontSize: '65%' }} color="red"
              className="fa-stack-1x">
          {numberOfNotification.length > 9 ? '9+' : numberOfNotification.length}
    </span>
    </span>
      </div>
      }


      {isDropNotificationMenu &&
      <NotificationCenter onAllSeen={() => onAllSeen()} dropMenu={() => handleDrop('dropNotification')}
                          onReadMessage={(message) => onReadMessage(message)} onAllRead={() => onAllReadMessage()}
                          data={data.sort((a, b) => b.id - a.id)} isLoadingData={false}/>}


      {(!isLoading && isDropProfileMenu) &&
      <>
        <ProfileIcon style={{ cursor: 'pointer' }} name="dropProfile"
                     className="fas fa-user-circle"/>

        <div style={{ display: 'flex', alignItems: 'center' }}>

          <div  style={{ display: 'flex', alignItems: 'center' }}>
            <Name style={{ cursor: 'pointer' }} name="dropProfile">
            <span style={{
            }}> {userName} </span>
            </Name>
            {identity.role[0] !== 'SysAdmin' &&
            <WrapCompanyName>
              <Company>({identity.organizationName}</Company><CompanyWrap>)</CompanyWrap></WrapCompanyName>}
          </div>


          <Icon name="dropProfile" style={{
            alignSelf: (isDropProfileMenu ? 'flex-end' : 'normal'),
            height: '10px',
            position: 'absolute',
            top: (isDropProfileMenu ? '9.5px' : '4px'),
            right: '-20px',
          }} className={isDropProfileMenu ? 'fas fa-sort-up' : 'fas fa-sort-down'}/>
        </div>

      </>
      }

      {(!isLoading && !isDropProfileMenu) &&
      <>
        <ProfileIcon style={{ cursor: 'pointer' }} name="dropProfile" onClick={() => handleDrop('dropProfile')}
                     className="fas fa-user-circle"/>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div  style={{ display: 'flex', alignItems: 'center' }}>
          <Name style={{ cursor: 'pointer' }} name="dropProfile" onClick={() => handleDrop('dropProfile')}>
            <span style={{
            }}> {userName} </span>
          </Name>
            {identity.role[0] !== 'SysAdmin' &&
              <WrapCompanyName>
                <Company onClick={() => handleDrop('dropProfile')}>({identity.organizationName}</Company><CompanyWrap>)</CompanyWrap></WrapCompanyName>}
          </div>
          <Icon name="dropProfile" onClick={() => handleDrop('dropProfile')} style={{
            alignSelf: (isDropProfileMenu ? 'flex-end' : 'normal'),
            height: '10px',
            position: 'absolute',
            top: (isDropProfileMenu ? '9.5px' : '4px'),
            right: '-20px',
          }} className={isDropProfileMenu ? 'fas fa-sort-up' : 'fas fa-sort-down'}/>
        </div>

      </>
      }

      {isDropProfileMenu && <ProfileMenu dropMenu={() => handleDrop('dropProfile')} closeMenu={closeMenu}/>}

    </div>
  )
}
export default Profile