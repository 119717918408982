import React, { useState } from 'react'
import { Icon, ItemWrapper, Text, Time } from './styled'
import { markAsRead } from '../../../../Services/Notification/mark-as-read'
import moment from 'moment'
import { Link } from 'react-router-dom'


const NotificationItem = ({ data, onReadMessage }) => {
  const [isSeen, setIsSeen] = useState(data.seen)
  const handleIsRead = async () => {
    setIsSeen(true)
    await markAsRead({
        id: [data.id],
      },
    )
    onReadMessage(data.id)
  }


  return (
    <div style={{ backgroundColor: 'white' }}>
      <ItemWrapper isSeen={isSeen}>
        <div style={{ display: 'flex' }}>
          <Icon onClick={handleIsRead} style={{ color: isSeen ? 'lightgray' : '#33B58F', zIndex: '20' }}
                className="far fa-check-circle"/>
          {data.uri !== null &&
          <Link to={data.uri} style={{ textDecoration: 'none', color: !isSeen ? 'black' : 'gray' }}>
            <div style={{ paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', textAlign: 'left' }}>
              <Text style={{ fontWeight: 'bold' }}>
                {data.title}
              </Text>
              <Text>
                {data.message}
              </Text>
            </div>
          </Link>
          }
          {data.uri === null &&
            <div style={{ paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', textAlign: 'left',  textDecoration: 'none', color: !isSeen ? 'black' : 'gray' }}>
              <Text style={{ fontWeight: 'bold' }}>
                {data.title}
              </Text>
              <Text>
                {data.message}
              </Text>
            </div>
          }
        </div>
        {data.uri !== null &&
        <Link to={data.uri} style={{ textDecoration: 'none', color: 'gray' }}>
          <Time>
            {moment(data.createdDate).format('HH:mm')}
          </Time>
        </Link>
        }
        {data.uri === null &&

          <Time style={{ textDecoration: 'none', color: 'gray' }}>
            {moment(data.createdDate).format('HH:mm')}
          </Time>

        }
      </ItemWrapper>

    </div>
  )
}

export default NotificationItem