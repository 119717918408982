import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../Resources/Stylesheets/General/popup.css';

export function RenderBooleanInput(inputInfo, instance) {
    return (
        <div className="form-label-input-block" key={inputInfo.key} item={inputInfo}>
            <div className="radio-buttons-block">
                <div className={"radio-button" + (instance.state.inputs[inputInfo.key] == true ? " active-radio" : "")}><input className="radio-btns" id={"radio-true-" + inputInfo.key} type="radio" name={inputInfo.key} value="true" onChange={instance.handleInputChange} required={inputInfo.required} defaultChecked /><label htmlFor={"radio-true-" + inputInfo.key}>Yes</label></div>
                <div className={"radio-button" + (instance.state.inputs[inputInfo.key] == false ? " active-radio" : "")}><input className="radio-btns" id={"radio-false-" + inputInfo.key} type="radio" name={inputInfo.key} value="false" onChange={instance.handleInputChange} required={inputInfo.required} /><label htmlFor={"radio-false-" + inputInfo.key}>No</label></div>
            </div>
            <label>{inputInfo.label}</label>
        </div>
    );
}