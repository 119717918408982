import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../Resources/Stylesheets/General/popup.css';

export function ValidateTemplate(key, added, instance, stateKey, callCheck = false) {
    if (key.length == 0) return null;
    else if (key.length > 0) { 
        $.ajax('/api/Templates/overview?key=' + key, {
            type: 'GET',
            async: true,
            contentType: 'application/json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
            },
            success: function (output, status, xhr) {
                if (status == "success") {
                    let checkDuplicates = added.filter((value, index, array) => { return value.id == output.id; });
                    let state = instance.state[stateKey];
                    state.key = (checkDuplicates.length == 0);
                    if (instance.state.newOrganization) {
                        let newOrg = instance.state.newOrganization;
                        newOrg.ctaAvailable = output.ctaAvailable;
                        instance.setState({ [stateKey]: state, newOrganization: newOrg });
                    }
                    else if (instance.state.newTemplate) {
                        let newTem = instance.state.newTemplate;
                        newTem.ctaAvailable = output.ctaAvailable;
                        instance.setState({ [stateKey]: state, newTemplate: newTem });
                    }
                    else {
                        instance.setState({ [stateKey]: state });
                    }
                    if (callCheck) instance.checkAddValidators();
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (textStatus == "error") {
                    let state = instance.state[stateKey];
                    state.key = false;
                    instance.setState({ [stateKey]: state });
                    if (callCheck) instance.checkAddValidators();
                }
            }
        });
    }
}