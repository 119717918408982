import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import '../../Resources/Stylesheets/General/header.css';
import '../../Resources/Stylesheets/General/statistics.css';
import { Identity } from '../../Services/IdentityService';
import { Roles, GetRole } from '../../Services/RoleService';
import { ValidateToken } from '../../Services/TokenValidityService';
import { GetStats } from '../../Services/StatsService'; 
import { RenderLoader } from './Loader';
import { getUserById } from '../../Services/UsersServices/get-user-by-id';

export class Statistics extends Component {
    static displayName = Statistics.name;

    constructor(props) {
        super(props);
        this.state = {
            identity: null,
            redirect: false,
            stats: {
                videosCount: 0,
                videosMinutes: 0,
                templatesCount: 0,
                usersCount: 0,
                organizationsCount: 0,
                videoMinutesLimit: 0,
                orgAdminViews: 0,
                organizationViews: 0,
                viewsCount: 0,
            },
            loading: false,
            userOverview: null
        };
        this.renderStatistics = this.renderStatistics.bind(this);
        this.loadUserStats = this.loadUserStats.bind(this);
        this.loadSysadminStats = this.loadSysadminStats.bind(this);
        this.loadOrgadminStats = this.loadOrgadminStats.bind(this);
    }

    componentWillMount() {
        ValidateToken();
        if (sessionStorage.getItem("userData")) {
            GetStats(this);
            this.setState({ identity: Identity(), userOverview: getUserById(Identity().userId) });
        }
        else {
            this.setState({ redirect: true });
        }
    }

    loadSysadminStats() {
        return (
            <div className="header-statistics-item item-statistics">
                    <div className="item-statistics-box">
                    <div className="big-stats-label">{this.state.stats.videosCount}</div>
                    <div className="small-stats-label">videos</div>
                    </div>
                <div className="item-statistics-box">
                    <div className="big-stats-label">{this.state.stats.videosMinutes}</div>
                    <div className="small-stats-label">minutes</div>
                </div>
                <div className="item-statistics-box">
                    <Link to={'/organizations'}>
                    <div className="big-stats-label">{this.state.stats.organizationsCount}</div>
                    <div className="small-stats-label">organizations</div>
                    </Link>
                    </div>
                <div className="item-statistics-box">
                    <div className="big-stats-label">{this.state.stats.usersCount}</div>
                    <div className="small-stats-label">users</div>
                </div>
            </div>
        );
    }

    loadOrgadminStats() { 
        return (
            <div className="header-statistics-item item-statistics">

            <div className="item-statistics-box">
                <Link to={'/my-videos'}>
                    <div className="big-stats-label">{this.state.stats.videosCount}</div>
                    <div className="small-stats-label">{/*out of <span className="stats-mark">100</span> */}videos</div>
                </Link>
            </div>


                <div className="item-statistics-box">
                    <div className="big-stats-label">{this.state.stats.videosMinutes}</div>
                    <div className="small-stats-label">out of {this.state.stats.videoMinutesLimit == 0 ? <span className="stats-mark">Unlimited</span> : <span><span className="stats-mark">{this.state.stats.videoMinutesLimit}</span> videos</span>}</div>
                </div>
                <div className="item-statistics-box">
                    <Link to={'/my-templates'}>

                    <div className="big-stats-label">{this.state.stats.templatesCount}</div>
                <div className="small-stats-label">templates</div>
                    </Link>
                </div>

                <div className="item-statistics-box">
                    <Link to={'/my-users'}>

                    <div className="big-stats-label">{this.state.stats.usersCount}</div>
                <div className="small-stats-label">users</div>
                    </Link>

                </div>
                <div className="item-statistics-box">
                    <Link to={'/my-videos'}>
                        <div className="big-stats-label">{this.state.stats.orgAdminViews}</div>
                        <div className="small-stats-label">Admin Views</div>
                    </Link>
                </div>
                <div className="item-statistics-box">
                    <Link to={'/my-videos'}>
                        <div className="big-stats-label">{this.state.stats.organizationViews}</div>
                        <div className="small-stats-label">Organization Views</div>
                    </Link>
                </div>



            </div>
        );
    }

    loadUserStats() {
        return (
            <div className="header-statistics-item item-statistics">

                <div className="item-statistics-box">
                    <Link to={'/my-videos'}>
                    <div className="big-stats-label">{this.state.stats.videosCount}</div>
                    <div className="small-stats-label">{/*out of <span className="stats-mark">50</span> */}videos</div>
                    </Link>
                    </div>

                <div className="item-statistics-box">
                    <div className="big-stats-label">{this.state.stats.videosMinutes}</div>
                    <div className="small-stats-label">{/*out of <span className="stats-mark">250</span> */}minutes</div>
                    </div>


                <div className="item-statistics-box">
                    <Link to={'/my-videos'}>
                    <div className="big-stats-label">{this.state.stats.generatingVideosCount}</div>
                    <div className="small-stats-label">generating</div>
                    </Link>
                    </div>

                <div className="item-statistics-box">
                        <div className="big-stats-label">{this.state.stats.templatesCount}</div>
                        <div className="small-stats-label">templates</div>
                </div>

                <div className="item-statistics-box">
                    <Link to={'/my-videos'}>
                        <div className="big-stats-label">{this.state.stats.viewsCount}</div>
                        <div className="small-stats-label">views</div>
                    </Link>
                </div>
            </div>
        );
    }

    renderStatistics() {
        let stats = null;
        const role = GetRole(this.state.identity.role);
        if (role === Roles.SysAdmin) stats = this.loadSysadminStats();
        else if (role === Roles.OrgAdmin) stats = this.loadOrgadminStats();
        else stats = this.loadUserStats();

        return stats;
    } 

    render() {
        if (this.state.redirect) return (<Redirect to={'/login'} />);
        let statistics = this.state.loading ? RenderLoader() : this.renderStatistics(); 
        return (
            <div className="header-statistics block-layout" style={{textAlign: "center"}}>
                { statistics }                    
            </div>
        );
    }
}