import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Header } from '../Components/General/Header';
import { Statistics } from '../Components/General/Statistics';
import { Footer } from '../Components/General/Footer';

const MenuExtendedLayout = ({ children, ...rest }) => (
    <div>
    <main id="main">
        <header>
                <Header {...rest} />
            <Statistics />
        </header>
        {children}
    </main>
    <Footer />
    </div>
);

const MenuExtendedLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <MenuExtendedLayout {...rest}>
                <Component {...matchProps} />
            </MenuExtendedLayout>
        )} />
    )
};

export default MenuExtendedLayoutRoute; 