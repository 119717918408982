import styled from "styled-components"

export const Button = styled.button`
    outline: none;
    border: 1px solid #33B58F;
    color: #ffffff;
    background-color: #33B58F;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12.5px 20px;
    font-weight: bold;
    width: 300px;
    position: relative;
    border-radius: 50px;
    margin-top: 15px;
`
