import React from "react"
import {Identity} from "../../../../Services/IdentityService"
import {Message} from "./styled"
import moment from 'moment';


const LastLog = () => {
  const lastLogin = Identity().lastLogin.split(",")
  return(
    <Message>
      Last logged in on {moment(lastLogin[0]).format('dddd')} {moment(lastLogin[0]).format("YYYY/M/D HH:mm")} {lastLogin[1]}
    </Message>
  )
}
export default LastLog