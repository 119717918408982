import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import '../../Resources/Stylesheets/General/header.css';
import { Identity } from '../../Services/IdentityService';
import { ValidateToken } from '../../Services/TokenValidityService';
import * as routes from "./../../../routes"
import { GetRole } from '../../Services/RoleService';
import cookie from 'react-cookies';
import Profile from './Profile'

export class Header extends Component {
    static displayName = Component.displayName;

    constructor(props) {
        super(props);
        this.state = {
            identity: null,
            redirect: false,
            isLoading: true,
        }; 
    }  

    async componentWillMount() {
        ValidateToken(); 
        if (sessionStorage.getItem("userData")) {
            if (cookie.load("isOneTimePassword")) {
                this.setState({ redirect: true });
            }
            else {
                let data = Identity()
                this.setState({ identity: data, isLoading: false})
            }
        }
        else {
            this.setState({ redirect: true });
        }
    }

    render() {
        if (this.state.redirect) return (<Redirect to={'/login'} />); 
        let isLoading = this.state.isLoading; 
        return (
        <div className="top-panel block-layout">
            <div className="top-panel-item">
                <Link to={'/dashboard'}>
                    <img className="top-panel-logo big" src="./Resources/Images/General/Personalizard.png"/>
                    <img className="top-panel-logo small" src="./pe_favicon.png"/>
                </Link>
            </div>
            <div className="top-panel-item top-panel-title" id="page-title">
                {this.props.backBtn ? <Link to={'/dashboard'}><i className="fas fa-angle-left"></i>{this.props.title}
                </Link> : this.props.title}
            </div>
            {!isLoading &&
            <div className="top-panel-item">
                    <div className="top-panel-user-options">
                        <Profile identity={this.state.identity} />
                </div>
            </div>
              }
        </div>
        );
    }
}