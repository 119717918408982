import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';

export function DeleteVideoById(id, instance) {
    $.ajax('/api/Videos/' + id, {
        type: 'DELETE',
        async: true,
        contentType: 'application/json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        success: function (output, status, xhr) {
            if (status == "success") {
                let filtered = instance.state.videos.filter((value, index, array) => { return value.id != id });
                instance.setState({ videos: filtered });
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus == "error")
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Process warning!</h1>
                                <p>Error while deleting video.</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
        }
    });
}