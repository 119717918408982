import jwt from 'jsonwebtoken';

export function Identity(){
    const decoded = jwt.decode(sessionStorage.getItem("userData"), { complete: true }).payload;
    let mapped = {
        organizationId: decoded.OrganizationId,
        organizationName: decoded.OrganizationName,
        aud: decoded.aud,
        exp: decoded.exp,
        role: decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
        emailAddress: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
        name: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
        userId: parseInt(decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]),
        iss: decoded.iss,
        nbf: decoded.nbf,
        lastLogin: decoded.lastLogin
    };
    return mapped;
}