import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../Resources/Stylesheets/General/popup.css';

export function RenderNumberInput(inputInfo, instance) {
    return (
        <div className={instance.getInputClass(inputInfo.key)} key={inputInfo.key} item={inputInfo}>
            <input id={inputInfo.key} type="number" step="any" name={inputInfo.key} value={instance.state.inputs[inputInfo.key]} onChange={instance.handleInputChange} required={inputInfo.required} />
            <label htmlFor={inputInfo.key}>{inputInfo.label}</label>
        </div>
    );
}