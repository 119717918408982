import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import cookie from 'react-cookies';
import $ from 'jquery';
import '../Resources/Stylesheets/LogIn/signin.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../Resources/Stylesheets/General/popup.css';
import { ValidatePassword } from '../Validators/PasswordValidatorService';
import { OnetimeFirstChange } from '../Services/UsersServices/OnetimeFirstChangeService';

export class OneTimePassword extends Component {
    static displayName = OneTimePassword.name;

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordAgain: '',
            redirectLogin: false,
            redirectDashboard: false,
            validators: {
                password: null,
                passwordAgain: null
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getBtnClass = this.getBtnClass.bind(this);
    }

    componentWillMount() {
        document.title = "One time password | Personalizard";
        if (sessionStorage.getItem("userData")) {
            if (!cookie.load("isOneTimePassword"))
                this.setState({ redirectDashboard: true });
        } else
            this.setState({ redirectLogin: true });
    }

    handleInputChange(event) {
        let validators = this.state.validators;
        switch (event.target.name) {
            case "password":
                validators.password = ValidatePassword(event.target.value);
                this.setState({ validators: validators });
                break;
            case "passwordAgain":
                validators.passwordAgain = ValidatePassword(event.target.value);
                this.setState({ validators: validators });
                break;
        }
        this.setState({
            [event.target.name]: event.target.value
        });
        this.checkInputLabels(event.target.name);
    }

    handleSubmit(event) {
        if (this.state.validators.password && this.state.validators.passwordAgain) {
            if (this.state.password === this.state.passwordAgain) {
                OnetimeFirstChange("", this.state.password, this)
            }
        }
        event.preventDefault();
    }

    getBtnClass() {
        if (this.state.validators.password && this.state.validators.passwordAgain) {
            if (this.state.password === this.state.passwordAgain) {
                return "form-submit-button form-submit-button-active";
            }
        }
        return "form-submit-button";
    }

    checkInputLabels(name) {
        let element = $("#user-" + name);
        if (element.val() != "")
            element.parent().children("label").addClass("input-filled");
        else
            element.parent().children("label").removeClass("input-filled");
    }

    render() {
        let mainStyle = { paddingTop: "10%" };
        if (this.state.redirectDashboard) return (<Redirect to={'/dashboard'} />);
        if (this.state.redirectLogin) return (<Redirect to={'/login'} />);
        return (
            <main id="main" style={mainStyle}>
                                            <div className="signin-main-logo-block">
                    <img className="signin-main-logo" src="./Resources/Images/General/Personalizard.png"/>
</div>
                <div className="signin-form">
                    <form onSubmit={this.handleSubmit} autoComplete="off">
                        <div className="adv-form-label-input-block">
                            <input id="user-password" type="password" name="password" value={this.state.password} onChange={this.handleInputChange} autoComplete="off" required />
                            <label htmlFor="user-password">Set your new password</label>
                                                    <i className="fas fa-key"></i>
                                                    <p className="form-p-desc">At least 6 chars</p>
        </div>
                        <div className="adv-form-label-input-block">
                            <input id="user-passwordAgain" type="password" name="passwordAgain" value={this.state.passwordAgain} onChange={this.handleInputChange} autoComplete="off" required />
                            <label htmlFor="user-passwordAgain">Password again</label>
                                                        <i className="fas fa-key"></i>
                        </div>
                        <button className={this.getBtnClass()}>Confirm</button>
    </form>
                            </div>
                        </main>
                        );
    }
}