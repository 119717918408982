import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';

export function ChangeOrganizationLogo(logo, id, instance) {
    $.ajax('/api/Image/organization/logo/' + id + '?logoUrl=' + logo, {
        type: 'POST',
        async: true,
        contentType: 'application/json',
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("userData"));
        },
        success: function (output, status, xhr) {
            instance.setState({ orgLogo: logo, isUploading: false });
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus === "error")
                instance.setState({ orgLogo: null, isUploading: false });
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle" />Fetch warning!</h1>
                                <p>Error while updating organization logo.</p>
                                <div className="line" />
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
        }
    });
}