﻿
import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import '../../../Resources/Stylesheets/General/popup.css' 

export function RenderLogoInput(instance) {
  return (
      <div className="form-label-input-block">

      <input id={'file-logo'} accept=".png" name={'logo'} type="file"
             style={{ display: 'none' }} onChange={ e => instance.handleLogoInputChange(this, e)}/>

      <div className="image-input-block">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <input id={'file-logo'} className="image-input" type="text"
                      value={instance.state.isUploading ? instance.state.orgLogo : ''} disabled />

                  <div style={{ width: '190px', marginTop: '5px', paddingLeft: '10px' }}>
                      {!instance.state.isUploading && <button type="button" className="add-btn add-btn-active" data-key={'logo'} data-id={'file-logo'}
                          onClick={instance.openFileChooser} ><i className="fas fa-paperclip" />Select file</button>}
                      {instance.state.orgLogo &&
                          <button type="button" className="remove-image" style={{ position: 'absolute', right: '125px', top: '15px' }} data-id={'logo'}
                              onClick={e => instance.removeImage(instance, e)}><i
                                  className="far fa-times-circle" /></button>}
            {(instance.state.isUploading === true) &&
                          <button type="button" className="add-btn add-btn-active" data-id={'file-logo'}><i
              style={{ fontSize: '20px', padding: '8px 0px' }} className="fas fa-circle-notch fa-spin"/></button>
            }
          </div>
        </div>
      </div>

          {!instance.state.isUploading && <img style={{ maxWidth: "300px", maxHeight: "80px", objectFit: "contain", objectPosition: "center left" }} src={(instance.state.orgLogo) ? instance.state.orgLogo : './Resources/Images/General/Personalizard.png'} />}

      <label htmlFor={'file-logo'}>Logo
        <i style={{bottom: "15px"}} className="far fa-question-circle">
          <div>Upload PNG image</div>
        </i>
      </label>

    </div>
  )
}