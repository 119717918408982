// MAIN NAVIGATION ROUTES
export const DASHBOARD = '/dashboard'
export const VIDEOS = '/my-videos'
export const VIDEO = "/video/:videoId"
export const USER = '/my-users'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const TEMPLATE = '/my-templates'
export const ORGANIZATION = '/organizations'
export const NEWVIDEO = '/new-video'
export const SETTINGS = '/settings'
export const NOTIFICATION_SETTINGS = '/notification-settings'
export const SUPPORT = '/support'
export const RERENDER = '/re-render/:videoId'

