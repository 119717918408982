import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';
import React, { Component } from 'react';

import { ValidateNumber } from '../../Validators/NumberValidatorService';
import { ValidatePlace } from '../../Validators/PlaceValidatorService';
import { ValidateText } from '../../Validators/TextValidatorService';
import { ValidateUrl } from '../../Validators/UrlValidatorService';

export function GenerateInputObject(template) {
    let object = {}; 
    let validators = {}; 
    console.log(template)
    template.fields.map(input => {
        switch (input.type) {
            case "place":
                object[input.key] = null;
                validators[input.key] = {};
                validators[input.key]["validate"] = ValidatePlace;
                validators[input.key]["isRequired"] = input.required;
                validators[input.key]["isValid"] = (input.required ? null : true); 
                break;
            case "number":
                object[input.key] = "";
                validators[input.key] = {};
                validators[input.key]["validate"] = ValidateNumber;
                validators[input.key]["isRequired"] = input.required;
                validators[input.key]["isValid"] = (input.required ? null : true); 
                break;
            case "image":
                object[input.key] = {};
                object[input.key].name = "No picture";
                object[input.key].file = null;
                object[input.key].isUploading = false
                validators[input.key] = {};
                validators[input.key]["isRequired"] = input.required;
                validators[input.key]["isValid"] = (input.required ? null : true); 
                break;
            case "enum":
                object[input.key] = input.options[Object.keys(input.options)[0]].value;
                break;
            case "boolean":
                object[input.key] = true;
                break;
            case "text":
                object[input.key] = "";
                validators[input.key] = {};
                validators[input.key]["validate"] = ValidateText;
                validators[input.key]["isRequired"] = input.required;
                validators[input.key]["isValid"] = (input.required ? null : true); 
                break;
            case "page":
                if (input.key == "cta") object[input.key] = template.ctaUrl;
                else object[input.key] = "";
                validators[input.key] = {};
                validators[input.key]["validate"] = ValidateUrl;
                validators[input.key]["isRequired"] = input.required;
                if (input.key == "cta" && !template.ctaVisible && template.ctaUrl != "") validators[input.key]["isValid"] = true
                else if (input.key == "cta" && template.ctaVisible && template.ctaUrl != "") validators[input.key]["isValid"] = true
                else validators[input.key]["isValid"] = (input.required ? null : true);
                break;
            default:
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Init warning!</h1>
                                <p>Can´t initialize template input of type {input.type} - {input.label}</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });
        }
    });
    return [object, validators];
} 