import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';

export function GetVideoById(id, instance) {
    $.ajax('/api/Videos/' + id + (sessionStorage.getItem("userData") ? '?access_token=' + sessionStorage.getItem("userData") : ''), {
        type: 'GET',
        async: true,
        contentType: 'application/json',
        success: function (output, status, xhr) {
            if (status == "success") {
                let parsedUrl = output.url.split("/");
                let customer = parsedUrl[parsedUrl.length - 3];
                let campaign = parsedUrl[parsedUrl.length - 2];
                let uid = parsedUrl[parsedUrl.length - 1].replace(".mp4", "");
                let isDev = (window.location.hostname === "devpersonalizard.azurewebsites.net");
                instance.setState({ video: output, loading: false, customer: customer, campaign: campaign, uid: uid, isDev: isDev });
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (textStatus == "error") {
                instance.setState({ video: null, loading: false });
                /*confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1><i className="fas fa-exclamation-triangle"></i>Fetch warning!</h1>
                                <p>Error while fetching video.</p>
                                <div className="line"></div>
                                <button className="yes" onClick={onClose}>Ok</button>
                            </div>
                        );
                    }
                });*/
            }
        }
    });
}