import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Footer } from '../Components/General/Footer'

const NoMenuLayout = ({ children }) => (
    <main>
        {children}
    </main>
);

const NoMenuLayoutRoute = ({ component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <NoMenuLayout>
                <Component {...matchProps} />
                <Footer />
            </NoMenuLayout>
        )} />
    )
};

export default NoMenuLayoutRoute; 