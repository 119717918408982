import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import cookie from 'react-cookies';

export class LogOut extends Component {
    static displayName = LogOut.name;

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        document.title = "Log Out | Personalizard";
        sessionStorage.clear();
        localStorage.clear();
        cookie.remove("token", { path: '/' });
        cookie.remove("isOneTimePassword", { path: '/' });
    }

    render() {
        return (<Redirect to={'/login'} />);
    }
}