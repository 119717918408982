import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import $ from 'jquery';
import { ValidateToken } from '../../Services/TokenValidityService';
import { GetVideoTemplate } from '../../Services/VideoServices/LoadVideoTemplateService';
import '../../Resources/Stylesheets/NewVideo/newvideo.css';
import { GetTemplatesByOrganization } from '../../Services/TemplatesServices/TemplatesByOrganizationService';
import { GetTemplateDetail } from '../../Services/TemplatesServices/GetTemplateDetail';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../Resources/Stylesheets/General/popup.css';
import * as AComplete from 'react-autocomplete';
import { RenderLoader } from '../../Components/General/Loader';

// Importing input components 
import { UploadImage } from '../../Services/UploadImageService';
import { GenerateVideo } from '../../Services/VideoServices/GenerateVideoService';
import { ValidateText } from '../../Validators/TextValidatorService';
import { NewVideo } from '../NewVideo'
import { ReRenderVideo } from '../../Services/VideoServices/ReRenderVideoService';


export class ReRender extends Component {
    static displayName = ReRender.name;


    constructor(props) {
        super(props);
        const { match: { params } } = this.props
        this.state = {
            loading: false,
            videoId: params.videoId,
            key: this.props.location.search.replace("?key=", ""), 
            redirect: false, 
            template: null,
            templateLoaded: false,
            videoName: "",
            isNameValid: null,
            inputs: null,
            validators: null,
            redirectVideos: false,
            isAllValid: false,
        };
        this.generateVideo = this.generateVideo.bind(this);
        this.openFileChooser = this.openFileChooser.bind(this);
        this.openImage = this.openImage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.getInputClass = this.getInputClass.bind(this);
        this.getNameInputClass = this.getNameInputClass.bind(this);
        this.checkValidators = this.checkValidators.bind(this);
    }

    componentWillMount() {
        ValidateToken();
        document.title = "Re-render video | Personalizard";
        if (sessionStorage.getItem("userData")) { 
            GetTemplateDetail(this.state.key, this)
        }
        else {
            this.setState({ redirect: true });
        }
    } 

    handleNameChange(e) {
        this.setState({ videoName: e.target.value, isNameValid: ValidateText(e.target.value) });
        this.checkValidators();
    }

    handleInputChange(e) {
        let inputs = this.state.inputs;
        let validators = this.state.validators;
        if (e.target.type == "place") {
            inputs[e.target.name] = e;
            validators[e.target.name].isValid = this.state.validators[e.target.name].validate(e, validators[e.target.name].isRequired);
        }
        else if (e.target.type == "file") {
            if (e.target.files.length > 0) {
                inputs[e.target.name]["name"] = e.target.files[0].name;
                inputs[e.target.name]["file"] = e.target.files[0];
                inputs[e.target.name]["isUploading"] = true
                UploadImage(this, e.target.name, e.target.files[0]);
            } else {
                validators[e.target.name].isValid = (validators[e.target.name].isRequired ? null : true);
            }
        }
        else if (e.target.type == "text" || e.target.type == "number" || e.target.type == "select-one" || e.target.type == "page") {
            inputs[e.target.name] = e.target.value;
            if (e.target.type == "text" || e.target.type == "number" || e.target.type == "page")
                validators[e.target.name].isValid = this.state.validators[e.target.name].validate(e.target.value, validators[e.target.name].isRequired);
        }
        else if (e.target.type == "radio") {
            inputs[e.target.name] = (e.target.value == "true");
        }
        this.setState({ inputs: inputs, validators: validators });
        this.checkValidators();
    }

    openFileChooser(event) {
        let validators = this.state.validators;
        validators[event.currentTarget.dataset.key].isValid = false;
        this.setState({ validators: validators });
        this.checkValidators();
        $("#" + event.currentTarget.dataset.id).click();
        event.preventDefault();
    }

    openImage(event) {
        window.open(this.state.inputs[event.currentTarget.dataset.id].href, "_blank");
    }

    removeImage(event) {
        const key = event.currentTarget.dataset.id;
        let inputs = this.state.inputs;
        inputs[key] = { name: "No picture", file: null };
        this.setState({ inputs });
    }

    checkValidators() {
        let isValid = true;
        Object.keys(this.state.validators).map((value, index, array) => {
            isValid &= this.state.validators[value].isValid;
        });
        isValid &= this.state.isNameValid;
        isValid = isValid ? true : false
        this.setState({ isAllValid: isValid });
    }

    generateVideo(event) {
        if (this.state.isAllValid) {
            this.setState({ loading: true });
            ReRenderVideo(this, this.state.videoId);
        }
        event.preventDefault();
    }

    getTemplates() {
        return this.state.templates;
    }

    getNameInputClass() {
        if (this.state.isNameValid == null) return "form-label-input-block";
        if (this.state.isNameValid == true) return "form-label-input-block valid-field";
        else return "form-label-input-block nonvalid-field";
    }

    getInputClass(input) {
        if (this.state.validators[input].isValid == null) return "form-label-input-block";
        if (this.state.validators[input].isValid == true) return "form-label-input-block valid-field";
        else return "form-label-input-block nonvalid-field";
    }

    render() {
        if (this.state.redirect) return (<Redirect to={'/login'} />);
        if (this.state.redirectVideos) return (<Redirect to={'/my-videos'} />);

        let loader = (!this.state.templateLoaded || this.state.loading) ? RenderLoader() : "";
        let templateContents = NewVideo.renderTemplateComponents(this);

        return (
            <main className="main-content block-layout">
                {loader}
                <section className="section-layout"> 
                    {templateContents}
                </section>
            </main>
        );
    }
}