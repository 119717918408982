import React, {useState, useEffect} from "react"
import { postSupport } from "./../../Services/Support/post-support"
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

const SupportForm = () => {
  const [form, setForm] = useState({
    subject: "",
    message: ""
  })

  useEffect(() => {
    if (form.message.length > 0 && form.subject.length > 0) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  })
  const [isValid, setIsValid] = useState(false)

  const handleChange = ({target}) => {
    setForm({
        ...form,
        [target.id]: target.value
    });
  }


  const handleSubmit = async (event) => {
    event.preventDefault()
    if (isValid) {
      await postSupport({
        data: form
      })
      setForm({
        subject: "",
        message: "",
      })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1><i class="fas fa-check-double"></i>Message sent</h1>
                        <p>Your message has been successfully send</p>
                        <div className="line"></div>
                        <button className="yes" onClick={onClose}>Ok</button>
                    </div>
                )
            },
        })
    } else { 
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1><i className="fas fa-exclamation-triangle"></i>Form warning!</h1>
                        <p>Check if form is filled in correctly.</p>
                        <div className="line"></div>
                        <button className="yes" onClick={onClose}>Ok</button> 
                    </div>
                )
            },
        })
    }
  }

  return (
    <main className="main-content block-layout">
      <section className="section-layout">
        <div className="form-label-input-block autocomplete">
          <form>
            <div className="colored-layout">
              <h1>Contact Us</h1>
              <div style={{marginTop: "16px"}} key="subject">
                <label htmlFor="subject">Subject</label>
                <input id="subject" type="text" name="subject" value={form.subject} onChange={(e) => handleChange(e)} required={true} />
              </div>
            <div style={{marginTop: "16px"}} key="yourMessage">
              <label htmlFor="yourMessage">Your Message</label>
              <textarea id="message"  name="yourMessage" style={{height: "100px", width: "100%"}} value={form.message} onChange={(e) => handleChange(e)} required={true} />
            </div>
            <div className="save-button">
              <button className={ isValid ? "form-submit-button form-submit-button-active" : "form-submit-button"} onClick={(e) => handleSubmit(e)} style={{ marginTop: "32px"}}>SEND</button>
            </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  )
}
export default SupportForm