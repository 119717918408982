import React from "react"
import {Button} from './styled'
import {Link} from 'react-router-dom'


const NoAuthenticated = () => {
  return(
  <div>
    <div>
      You are not authenticated
    </div>
    <Link to={"/Dashboard"}>
    <Button>
      Back to Dashboard
    </Button>
    </Link>
  </div>
  )
}

export default NoAuthenticated