import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import $ from 'jquery'
import '../../Resources/Stylesheets/MyVideos/myvideos.css'
import moment from 'moment'
import 'moment-duration-format'
import { ValidateToken } from '../../Services/TokenValidityService'
import { GetVideosById } from '../../Services/VideoServices/VideoByIdService'
import { DeleteVideoById } from '../../Services/VideoServices/DeleteVideoByIdService'
import { Identity } from '../../Services/IdentityService'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import '../../Resources/Stylesheets/General/popup.css'
import { GetRole, Roles } from '../../Services/RoleService'
import Autocomplete from 'react-autocomplete'
import { GetUsersByOrganization } from '../../Services/UsersServices/UsersByOrganizationService'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { RenderLoader } from '../../Components/General/Loader'
import { HubConnectionBuilder } from '@aspnet/signalr'
import { TransparentButton } from './../../Components/FormComponents/Button/styled'
import { Icon, IconWithLabel, Label, SharePanel } from './styled'
import { ShareByFacebook, ShareByLinkedIn, ShareByTwitter } from './Share/ShareByApp'
import { ShareByEmail } from '../../Services/VideoServices/ShareByEmailService'
import { ReCaptcha } from 'react-recaptcha-v3'
import config from './../../../config'

const baseUrl = config.baseUrl


export class MyVideos extends Component {
  static displayName = MyVideos.name

  constructor(props) {
    super(props)
    this.state = {
      videos: [],
      loading: true,
      redirect: false,
      identity: null,
      search: '',
      searchUser: null,
      pageDone: 1,
      perPage: 15,
      sortByLabel: 'Sort by date',
      sortOption: {
        id: 'views',
        text: 'Sort by views',
      },
      isSortToggle: false,
      isSorted: false,
      recaptcha: '',
    }
    this.deleteVideo = this.deleteVideo.bind(this)
    this.toggleVideosFilter = this.toggleVideosFilter.bind(this)
    this.searchByUser = this.searchByUser.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.getSearchBtnClass = this.getSearchBtnClass.bind(this)
    this.copiedClipboard = this.copiedClipboard.bind(this)
    this.resetSearch = this.resetSearch.bind(this)
    this.handleSortVideos = this.handleSortVideos.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
  }

  componentWillMount() {
    ValidateToken()
    document.title = 'My videos | Personalizard'
    if (sessionStorage.getItem('userData')) {
      const identity = Identity()
      if (GetRole(identity.role) === Roles.OrgAdmin) GetUsersByOrganization(this)
      GetVideosById(identity.userId, this)
      this.setState({ identity: identity })

      // Rerender component when video is rendered
      let connection = new HubConnectionBuilder().withUrl('/hubs/videos', {
        accessTokenFactory: function() {
          return sessionStorage.getItem('userData')
        },
      }).build()
      connection.on('video-rendered', data => {
        if (this.state.searchUser != null) {
          this.setState({ loading: true })
          GetVideosById(this.state.searchUser.id, this)
        } else {
          this.setState({ loading: true })
          GetVideosById(identity.userId, this)
        }
      })
      connection.start()
      this.setState({ hub: connection })
    } else {
      this.setState({ redirect: true })
    }
  }

  componentWillUnmount() {
    this.state.hub.stop()
  }


  toggleSort(instance) {
    instance.setState(prevState => ({
      isSortToggle: !prevState.isSortToggle,
    }))
  }

  sortVideo(id, instance) {
    switch (id) {
      case 'views':
        if (this.state.isSorted) {
          this.setState(prevState => ({
            videos: prevState.videos.sort((a, b) => b.seen - a.seen),
          }))
          this.setState({
            isSorted: false,
          })
        } else {
          this.setState(prevState => ({
            videos: prevState.videos.sort((a, b) => a.seen - b.seen),
          }))
          this.setState({
            isSorted: true,
          })
        }
        break
      default:
        if (this.state.isSorted) {
          this.setState(prevState => ({
            videos: prevState.videos.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)),
          }))
          this.setState({
            isSorted: false,
          })
        } else {
          this.setState(prevState => ({
            videos: prevState.videos.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate)),
          }))
          this.setState({
            isSorted: true,
          })
        }
    }
  }

  toggleShareMenu(e) {
    $(e.currentTarget).parent().parent().children('.share-panel').toggle()
  }

  toggleShowMoreMenu(e) {
    $(e.currentTarget).parent().parent().children('.more-panel').toggle()
  }

  handleSortVideos(id, instance) {
    switch (id) {
      case 'date':
        instance.setState({
          sortByLabel: 'Sort by date',
          sortOption: {
            id: 'views',
            text: 'Sort by views',
          },
          sortKey: 'date',
        })
        instance.sortVideo(id)
        break
      case 'views':
        instance.setState({
          sortByLabel: 'Sort by views',
          sortOption: {
            id: 'date',
            text: 'Sort by date',
          },
          sortKey: 'views',
        })
        instance.sortVideo(id)
        break
      default:
        break
    }
    this.setState({
      isSortToggle: false,
    })

  }

  verifyCallback = (recaptchaToken) => {
    this.setState({ recaptcha: recaptchaToken })
  }

  static sendEmail(video, instance) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1><i className="fas fa-envelope"/>Share video via email</h1>
            <p>Please enter email address</p>
            <div className="line"/>
            <div className="form-label-input-block autocomplete" style={{ paddingBottom: '16px' }}>
              <form>
                <div style={{ textAlign: 'left' }}>
                  Email
                </div>
                <input type="text" id="emailInput" name="emailInput"/>
                <ReCaptcha
                  sitekey="6LeazrkUAAAAAB1ox2YG2BrAUDsLdVGX3xrMhV06"
                  action='share'
                  verifyCallback={instance.verifyCallback}
                />
              </form>
            </div>

            <button className="yes"
                    onClick={() => {
                      ShareByEmail(video, document.getElementById('emailInput').value, instance.state.recaptcha)
                      instance.setState({ recaptcha: '' })
                      onClose()
                    }}
            >

              <i className="far fa-paper-plane"/>Send
            </button>
            <button className="no" onClick={onClose}>No</button>
          </div>
        )
      },
    })
  }

  deleteVideo(event) {
    let params = event.currentTarget.dataset
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1><i className="fas fa-exclamation-triangle"></i>Delete warning!</h1>
            <p>This action is permanent. Would you like to proceed?</p>
            <div className="line"></div>
            <button className="yes"
                    onClick={() => {
                      DeleteVideoById(params.id, this)
                      onClose()
                    }}
            >
              <i class="far fa-trash-alt"></i>Yes
            </button>
            <button className="no" onClick={onClose}>No</button>
          </div>
        )
      },
    })
    event.preventDefault()
  }


  // Generating Video
  static renderInitGenerWidget(video, widgetClass, instance) {
    return (
      <div className={widgetClass.join(' ')} key={video.id} item={video}>
        <img className="video-item-image" src="/Resources/Images/General/video-preview.png"/>
        <div className="video-item-info">
          <h1 className="video-item-info-header">
            <i className="fas fa-video"></i>
            {video.name}
          </h1>
          <div className="video-item-info-in-progress">
            <i className="fas fa-hourglass-half"></i>
            Video in progress
            <button className="video-item-control item-delete-link" data-id={video.id} onClick={instance.deleteVideo}>
              <i className="far fa-trash-alt"></i>
              Delete
            </button>
          </div>
          <div className="video-item-info-details">
            <div className="video-item-info-time">
              <i className="far fa-clock"></i>
              --:--
            </div>
            <div className="video-item-info-date">
              <i className="far fa-calendar"></i>
              {moment(video.createdDate).format('YYYY/M/D HH:mm')}
            </div>
          </div>
        </div>
      </div>
    )
  }


  // Generated Video
  static renderDoneWidget(video, widgetClass, instance) {
    return (
      <div className={widgetClass.join(' ')} key={video.id} item={video}>
        <Link to={'/video/' + video.id}>
          <img className="video-item-image"
               src={video.thumbnailUrl ? video.thumbnailUrl : '/Resources/Images/General/video-preview.png'}/>
        </Link>
        <div className="video-item-info">
          <h1 className="video-item-info-header">
            <i className="fas fa-video"></i>
            {video.name}
          </h1>
          <div className="video-item-info-controls">
            <Link to={'/video/' + video.id}>
              <div className="video-item-control">
                <i className="far fa-play-circle"></i>
                Play
              </div>
            </Link>
            <button
              className="video-item-control share-btn"
              onClick={instance.toggleShareMenu}>
              <i className="fas fa-share-alt"/>
              Share
            </button>
                    {/* Adina does not have download functionality
            <a href={baseUrl + `/Videos/${video.id}/download`} download>
              <div className="video-item-control">
                <i className="far fa-arrow-alt-circle-down"/>
                Download
              </div>
            </a> */}
            <button className="video-item-control item-delete-link" data-id={video.id}
                    onClick={instance.toggleShowMoreMenu}>
              <i className="fas fa-ellipsis-h"/>
            </button>
          </div>
          <SharePanel className="video-item-info-controls share-panel" style={{ display: 'none' }}>

            <ShareByLinkedIn
              id={video.id}
              title={video.name}
              picture={video.screenshotUrl}
              icon={<Icon className="fab fa-linkedin-in"/>}/>
            <ShareByFacebook
              id={video.id}
              picture={video.screenshotUrl}
              icon={<Icon className="fab fa-facebook-f"/>}/>
            <ShareByTwitter
              id={video.id}
              picture={video.screenshotUrl}
              icon={<Icon className="fab fa-twitter"/>}>
            </ShareByTwitter>
            <a onClick={() => MyVideos.sendEmail(video, instance)}>
              <Icon className="fas fa-envelope"/>
            </a>

            <CopyToClipboard
              text={document.location.origin + '/video/' + video.id}>
              <Icon onClick={instance.copiedClipboard} className="fas fa-link"/>
            </CopyToClipboard>
          </SharePanel>
          <SharePanel className="video-item-info-controls more-panel" style={{ display: 'none' }}>
                    <IconWithLabel>
                        {/*
              <Link to={'/re-render/' + video.id + '/?key=' + video.templateKey}>
                <Icon style={{ paddingRight: '8px' }} className="fas fa-redo-alt"/>
                <Label>Re-render</Label>
              </Link> */}
              <IconWithLabel data-id={video.id} onClick={instance.deleteVideo}>
                <Icon style={{ paddingRight: '8px' }} className="fas fa-trash-alt"/>
                <Label>Delete</Label>
              </IconWithLabel>
            </IconWithLabel>
          </SharePanel>

          <div className="video-item-info-details">
            <div className="video-item-info-time">
              <i className="far fa-clock"/>
              {moment.duration(video.duration, 'seconds').isValid() ?
                moment.duration(video.duration, 'seconds').format('mm:ss', { trim: false }) :
                '--:--'}
            </div>
            <div style={{ minWidth: '130px' }} className="video-item-info-date">
              <i className="far fa-calendar"/>
              {moment(video.createdDate).format('M/D/YYYY HH:mm')}
            </div>
            <div className="video-item-info-seen">
              <i className="far fa-eye"/>
              {video.seen}
            </div>
          </div>
        </div>
      </div>
    )
  }


  /*
  static renderFailedWidget(video, widgetClass) {
      return (
          <div className={widgetClass.join(" ")} key={video.id} item={video}>
              <img className="video-item-image" src="/Resources/Images/General/video-preview.png" />
              <div className="video-item-info">
                  <h1 className="video-item-info-header">
                      <i className="fas fa-video"></i>
                      {video.name}
                  </h1>
                  <div className="video-item-info-in-progress">
                      <i className="fas fa-exclamation-triangle"></i>
                      Generating failed
                  </div>
                  <div className="video-item-info-details">
                      <div className="video-item-info-time">
                          <i className="far fa-clock"></i>
                          --:--
                      </div>
                      <div className="video-item-info-date">
                          <i className="far fa-calendar"></i>
                          {moment(video.createdDate).format("YYYY/M/D")}
                      </div>
                  </div>
              </div>
          </div>
      );
  }

  */


  static renderSingleWidget(video, widgetClass, instance) {
    if (video.state == 1 || video.state == 2)
      return MyVideos.renderInitGenerWidget(video, widgetClass, instance)
    else if (video.state == 3)
      return MyVideos.renderDoneWidget(video, widgetClass, instance)
    else
      return MyVideos.renderFailedWidget(video, widgetClass)
  }


  // Video sections
  static renderVideoWidgets(videos = [], stateFilter, instance) {
    const videoStates = ['Generating videos', 'Videos']
    const videoMessage = ['No generating videos found', 'No videos found']
    let widgetClass = ['video-item']
    if (stateFilter === 1) widgetClass.push('item-in-progress')
    else widgetClass.push('item-done')
    if (videos.length <= 0) {
      if (stateFilter !== 1) {
        return (
          <section className="section-layout">
            <h1 className="section-header-1">{videoStates[stateFilter - 1]}</h1>
            <div className="items-block">
              <div className="items-message">{videoMessage[stateFilter - 1]}</div>
            </div>
          </section>
        )
      }
    } else
      return (
        <section className="section-layout">
          {stateFilter !== 1 &&
          <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <h1 className="section-header-1">{videoStates[stateFilter - 1]}</h1>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <TransparentButton>
                  <h3 onClick={() => instance.toggleSort(instance)} className="section-header-3">
                    <div style={{ color: '#33B58F' }}>{instance.state.sortByLabel}</div>
                  </h3>
                  {instance.state.isSortToggle && <div className="sort-menu">
                    <div className="sort-item"
                         onClick={() => instance.handleSortVideos(instance.state.sortOption.id, instance)}>
                      {instance.state.sortOption.text}
                    </div>
                  </div>
                  }
                </TransparentButton>
              </div>
              <div
                style={{ paddingLeft: '8px', paddingRight: '24px', color: 'gray', fontSize: '12px', cursor: 'pointer' }}
                onClick={() => instance.sortVideo(instance.state.sortKey, instance)}>
                {instance.state.isSorted ? <i className="fas fa-sort-amount-down"/> :
                  <i className="fas fa-sort-amount-up"/>}
              </div>
            </div>
          </div>
          }
          <div className="items-block">
            {videos.map((video, index) =>
              stateFilter === 2 ?
                (((index >= (instance.state.perPage * (instance.state.pageDone - 1))) && (index < (instance.state.perPage * (instance.state.pageDone - 1) + instance.state.perPage))) ?
                  MyVideos.renderSingleWidget(video, widgetClass, instance) : '') : MyVideos.renderSingleWidget(video, widgetClass, instance))}
          </div>
          {(videos.length > instance.state.perPage && stateFilter === 2) &&
          <section className="section-layout">
            <div className="pagination">
              {(instance.state.pageDone - 1) * instance.state.perPage > 0 ?
                <i className="fas fa-chevron-left pagination-active"
                   onClick={() => instance.setState({ pageDone: (instance.state.pageDone - 1) })}/> :
                <i className="fas fa-chevron-left pagination-inactive"/>}
              <div className="pagination-items">
                <div className="pagination-item pagination-item-active">{instance.state.pageDone}</div>
              </div>
              {(((instance.state.pageDone - 1) * instance.state.perPage + instance.state.perPage) < videos.length) ?
                <i className="fas fa-chevron-right pagination-active"
                   onClick={() => instance.setState({ pageDone: (instance.state.pageDone + 1) })}/> :
                <i className="fas fa-chevron-right pagination-inactive"/>}
            </div>
          </section>}
        </section>
      )
  }

  searchByUser(event) {
    if (this.state.searchUser != null) {
      this.setState({ loading: true })
      GetVideosById(this.state.searchUser.id, this)
    } else {
      this.setState({ loading: true })
      GetVideosById(this.state.identity.userId, this)
    }
    event.preventDefault()
  }

  getSearchBtnClass() {
    if (this.state.searchUser != null) return ('form-submit-button form-submit-button-active')
    else return ('form-submit-button')
  }

  toggleVideosFilter() {
    if ($('.page-top-panel-body').is(':hidden')) $('.page-top-panel-header i').css({
      transform: 'rotateZ(180deg)',
      bottom: '2.5px',
    })
    else $('.page-top-panel-header i').css({ transform: 'rotateZ(0deg)', bottom: '0' })
    $('.page-top-panel-body').toggle()
  }

  getUsers() {
    return this.state.users.filter((value, index, array) => {
      return parseInt(value.id) !== parseInt(this.state.identity.userId)
    })
  }

  copiedClipboard() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1><i className="fas fa-check-double"/>Video sharing</h1>
            <p>Link copied to clipboard.</p>
            <div className="line"/>
            <button className="yes" onClick={onClose}>Ok</button>
          </div>
        )
      },
    })
  }

  resetSearch() {
    this.setState({ loading: true, search: '', searchUser: null })
    GetVideosById(this.state.identity.userId, this)
  }

  render() {
    if (this.state.redirect) return (<Redirect to={'/login'}/>)

    let loader = this.state.loading ? RenderLoader() : ''
    let generatingVideos = this.state.videos.filter((value, index, array) => {
      return value.state == 1 || value.state == 2
    })
    let doneVideos = this.state.videos.filter((value, index, array) => {
      return value.state == 3 || value.state == 4
    })

    let generatingContents = this.state.loading
      ? <div className="items-block">
        <div className="items-message">Loading...</div>
      </div>
      : MyVideos.renderVideoWidgets(generatingVideos, 1, this)
    let doneContents = this.state.loading && doneVideos.length > 0
      ? <div className="items-block">
        <div className="items-message">Loading...</div>
      </div>
      : MyVideos.renderVideoWidgets(doneVideos, 2, this)

    return (

      // Filter
      <main className="main-content block-layout">
        {loader}
        {GetRole(this.state.identity.role) === Roles.OrgAdmin &&
        <section className="section-layout">
          <div className="page-top-panel">
            <div className="page-top-panel-header" onClick={this.toggleVideosFilter}>
              <h1>Filter videos</h1>
              <i className="fas fa-chevron-down"></i>
            </div>
            <div className="page-top-panel-body">
              {this.state.users == null && <div>Loading...</div>}
              {(this.state.users != null &&
                this.getUsers().length == 0) && <div>No more users found</div>}
              {(this.state.users != null &&
                this.getUsers().length > 0) &&
              <form onSubmit={this.searchByUser}>
                <div className="form-label-input-block autocomplete">
                  <Autocomplete
                    getItemValue={(item) => item.name}
                    items={this.getUsers()}
                    renderItem={(item, isHighlighted) =>
                      <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                        {item.name}
                      </div>
                    }
                    value={this.state.search}
                    onChange={(event, search) => this.setState({ search, searchUser: null })}
                    onSelect={(search, item) => this.setState({ search, searchUser: item })}
                  />
                  <label htmlFor="user-name">By user´s name</label>
                  {this.state.searchUser != null ?
                    <button type="button" className="remove-image" onClick={this.resetSearch}><i
                      className="far fa-times-circle"></i></button> : ''}
                </div>
                <button className={this.getSearchBtnClass()}>Search</button>
              </form>
              }
            </div>
          </div>
        </section>
        }
        {generatingContents}
        {doneContents}
      </main>
    )
  }
}

/**/